<template>
  <BaseIcon 
    :size="size"
    :icon="`loader-${current_frame}`"
    />
</template>

<script>
export default {
    props: {
        size: String
    },
    data() {
        return {
            current_frame: 1,
            interval_id: 0,
            max_frames: 8
        }
    },
    methods: {
        nextFrame() {
            if (this.current_frame+1 == this.max_frames) {
                this.current_frame = 1;
            } else {
                this.current_frame++;
            }
        }
    },  
    mounted() {
        this.interval_id = setInterval(this.nextFrame, 100);
    },
    beforeDestroy() {
        clearInterval(this.interval_id);
    }
}   
</script>

<style>

</style>