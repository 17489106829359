function getMatchingColorForBackground(_bgcolor) {
  const isV1 = _bgcolor.startsWith('v1');

  const bgcolor = isV1 ? _bgcolor.replace('v1-', '') : _bgcolor;

  const light_const = "grey-2";
  const dark_const = "grey-10";

  const specific_rules = {
    "green-4": dark_const,
    "green-3": dark_const,
    "green-2": dark_const,
    "green-1": dark_const,
    "yellow-10": light_const,
    "yellow-9": light_const,
    "yellow-8": light_const,
    "yellow-7": light_const,
    "blue-1": dark_const,
    "blue-2": dark_const,
    "blue-3": dark_const,
    "grey-1": dark_const,
    "grey-2": dark_const,
    "grey-3": dark_const,
    "grey-4": dark_const,
    "grey-5": dark_const,
  };

  const general_rules = {
    white: dark_const,
    brown: dark_const,
    black: light_const,
    grey: light_const,
    green: light_const,
    red: light_const,
    yellow: dark_const,
    orange: light_const,
    blue: light_const,
  };

  if (specific_rules[bgcolor]) return specific_rules[bgcolor];

  const color_name = bgcolor.split("-")[0];

  const return_color = general_rules[color_name] || light_const;

  return isV1 ? `v1-${return_color}` : return_color;
}

function getColorSibling(color, delta) {
  if ((color == "grey-10" || color == "v1-grey-10") && delta == 1) return "black";
  if ((color == "grey-1" || color == "v1-grey-1") && delta == -1) return "white";

  let tokens = color.split("-");

  const isV1 = tokens[0] == 'v1';

  if (isV1) tokens = tokens.slice(1);

  //TODO: take care of out-of-bounds colors
  return [isV1 ? 'v1' : null, tokens[0], +tokens[1] + delta].filter(Boolean).join("-");
}

function isColorToken(possibleColorToken) {
  if (!possibleColorToken) return false;
  
  const targets = ['content', 'background', 'border', 'overlay'];

  return targets.some(t => possibleColorToken.startsWith(t));
}

function getOppositeColor(color) {
  if (isColorToken(color)) {
    return color.includes("inverse")
      ? color.replace("-inverse", "")
      : `${color}-inverse`;
  } else {
    return getMatchingColorForBackground(color);
  }
}

function getVariableForColorName(name) {
  return isColorToken(name) ? `qrw-${name}` : `${name}-color`;
}

function getColorTokenSibling(colorToken, delta) {
  const levels = ['primary', 'secondary', 'tertiary'];

  let result = colorToken;

  const foundTokenIndex = levels.findIndex(lvl => result.includes(lvl));

  if (foundTokenIndex === -1) return result;

  let nextColorTokenIndex = foundTokenIndex + delta;

  if (nextColorTokenIndex < 0) nextColorTokenIndex = 0;
  if (nextColorTokenIndex >= levels.length)  nextColorTokenIndex = levels.length-1;

  return result.replace(
    levels[foundTokenIndex],
    levels[nextColorTokenIndex]
  );
}

function makeColorTokenWithLevel(type, level, inverse) {
  return `qrw-${type}-${level}${inverse ? '-inverse' : ''}`;
}

function extractColorTokenLevel(colorToken) {
  return colorToken.split('-').slice(1).join('-');
}

function tokenToCssVar(name) {
  return `var(--${getVariableForColorName(name)})`;
}

export default {
  getMatchingColorForBackground,
  getColorSibling,
  isColorToken,
  getOppositeColor,
  getVariableForColorName,
  getColorTokenSibling,
  extractColorTokenLevel,
  makeColorTokenWithLevel,
  tokenToCssVar,
};
