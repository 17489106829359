<template>
  <button
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @mouseup="hovered = false"
    @touchstart="hovered = true"
    @touchend="hovered = false"
    :class="buttonClass"
    :style="buttonStyle"
    @click="onClicked"
  >
    <BaseIcon
      :size="iconSize"
      :icon="icon"
      v-if="icon && iconPosition != 'right'"
    />
    <div class="qrw-push-button-content">
      <slot />
    </div>
    <BaseIcon
      :size="iconSize"
      :icon="icon"
      v-if="icon && iconPosition != 'left'"
    />
  </button>
</template>

<script>
// import utils from "../../utils";

import buttonStylesBuilder from './buttonStylesBuilder'

export default {
  props: {
    size: {
      type: String,
      default: 'xs',
      validator: v => ['xs', 'md', 'sm', 'lg'].includes(v)
    },
    bgColor: {
      type: String,
      default: 'background-base-primary'
    },
    textColor: {
      type: String,
      default: 'content-base-primary'
    },
    borderColor: String,
    borderHoverColor: String,
    hoverColor: String,
    textHoverColor: String,
    variant: {
      type: String,
      default: 'primary',
      validator: v => ['primary', 'secondary', 'tertiary', 'fill', 'stroke', 'ghost', 'outlined', 'gray'].includes(v)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: v => ['left', 'right', 'both'].includes(v)
    }
  },
  data() {
    return {
      hovered: false
    }
  },
  methods: {
    onMouseEnter() {
      this.hovered = true
    },
    onMouseLeave() {
      this.hovered = false
    },
    onClicked() {
      this.hovered = false
      if (this.disabled) {
        this.$emit('disabledClick')
      } else {
        this.$emit('click')
      }
    }
  },
  computed: {
    iconSize() {
      return {
        xs: 'mxs',
        sm: 'xs',
        md: 'sm',
        lg: 'sm2'
      }[this.size]
    },
    buttonPaddings() {
      const paddingsMap = {
        xs: [5, 10],
        sm: [8, 16],
        md: [10, 20],
        lg: [12, 22]
      }

      const iconPaddingCompensation = 2

      let [top, right] = paddingsMap[this.size] || paddingsMap.xs
      let [bottom, left] = paddingsMap[this.size] || paddingsMap.xs

      if (this.icon && this.iconPosition != 'right') left -= iconPaddingCompensation
      if (this.icon && this.iconPosition != 'left') right -= iconPaddingCompensation

      if (this.variant == 'secondary' || this.variant == 'stroke' || this.variant == 'outlined') {
        //border should not be accounted
        top -= 1
        bottom -= 1
      }

      return {
        padding: `${top}px ${right}px ${bottom}px ${left}px`
      }
    },
    variantBasedStyles() {
      return buttonStylesBuilder({
        bgColor: this.bgColor,
        textColor: this.textColor,
        borderColor: this.borderColor,
        hoverColor: this.hoverColor,
        variant: this.variant,
        hovered: this.hovered,
        textHoverColor: this.textHoverColor,
        borderHoverColor: this.borderHoverColor
      })
    },

    buttonStyle() {
      return {
        ...this.buttonPaddings,
        ...this.variantBasedStyles
      }
    },
    buttonClass() {
      return {
        'qrw-push-button': true,
        [`size-${this.size}`]: true,
        [`qrw-label-${this.size}`]: true,
        bold: true,
        'w-100': this.fullWidth,
        disabled: this.disabled
      }
    }
  }
}
</script>

<style scoped>
.qrw-push-button {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  -webkit-appearance: none;
  align-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  text-align: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}

.size-xs {
  column-gap: 4px;
  border-radius: 6px;
}

.size-sm {
  column-gap: 6px;
  border-radius: 8px;
}

.size-md {
  column-gap: 8px;
  border-radius: 10px;
}

.size-lg {
  column-gap: 10px;
  border-radius: 12px;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.qrw-push-button-content {
  display: contents;
}
</style>
