<template>
  <div
    class="base-beta-radio-container"
    :class="{ [size]: true }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @click="onClick"
  >
    <div class="base-beta-radio-box" :class="{ [size]: true }">
      <div
        class="base-beta-radio"
        :class="{ active: isSelected, [size]: true }"
      >
        <div class="base-beta-radio-cell" v-if="isSelected"></div>
      </div>
    </div>

    <div
      :class="{
        bold: isSelected,
        'qrw-text-content-base-secondary': !isHovered && !isSelected,
        [`qrw-label-${size}`]: true,
      }"
      v-if="hasContent"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    checkedValue: {
      type: [String, Number, Object, Boolean],
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "lg",
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    onClick() {
      if (!this.readonly) {
        this.$emit("input", this.checkedValue);
      }
    },
  },
  computed: {
    isSelected() {
      return this.value === this.checkedValue;
    },
    hasContent() {
      return !!this.$slots.default;
    },
  },
};
</script>

<style scoped>
.base-beta-radio-container {
  display: flex;

  align-items: center;
  flex-direction: row;

  cursor: pointer;

  color: var(--qrw-content-base-primary);
}

.base-beta-radio-container.sm {
  column-gap: 8px;
}

.base-beta-radio-container.md {
  column-gap: 10px;
}

.base-beta-radio-container.lg {
  column-gap: 12px;
}

.base-beta-radio {
  display: flex;

  position: relative;

  box-sizing: border-box;

  border-radius: 40px;

  padding: 2px;

  width: 100%;
  height: 100%;

  border: 1.5px solid var(--qrw-border-base-secondary);

  cursor: pointer;

  align-items: center;
  justify-content: center;
}

.base-beta-radio-cell {
  background: var(--foundation-success-500-color);
  border-radius: 40px;
  display: flex;
  justify-self: center;
  align-self: center;
  width: 100%;
  height: 100%;
}

.base-beta-radio-container:hover .base-beta-radio,
.base-beta-radio.active {
  border-color: var(--foundation-success-500-color);
}

/* Sizes */
.base-beta-radio-box {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.base-beta-radio-box.lg {
  padding: 4px;
  width: 24px;
  height: 24px;
  max-width: 24px;
  min-height: 24px;
}

.base-beta-radio-box.md {
  padding: 3px;
  width: 20px;
  height: 20px;
  max-width: 20px;
  min-height: 20px;
}

.base-beta-radio-box.sm {
  padding: 2px;
  width: 16px;
  height: 16px;
  max-width: 16px;
  min-height: 16px;
}
</style>