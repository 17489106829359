<template>
  <div class="base-timepicker-input-wrapper">
    <BaseNumberInput
      type="text"
      :class="`timepicker-field-${size}`"
      hideControls
      :size="size"
      :max="23"
      :min="0"
      placeholder="00"
      @focus="isFocused = true"
      @blur="isFocused = false"
      :forceFocus="isFocused"
      :value="displayValues().hours"
      @input="(e) => onInput(e, 'hours')"
    />
    <div class="qrw-paragraph-md bold qrw-content-base-tertiary">:</div>
    <BaseNumberInput
      type="text"
      :class="`timepicker-field-${size}`"
      hideControls
      :size="size"
      placeholder="00"
      :max="59"
      :min="0"
      @focus="isFocused = true"
      @blur="isFocused = false"
      :forceFocus="isFocused"
      :value="displayValues().minutes"
      @input="(e) => onInput(e, 'minutes')"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: [String, Date, Object, Number],
    disabled: Boolean,
    valid: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "sm",
      validator: (v) => ["sm", "md", "lg"].includes(v),
    },
  },
  data() {
    return {
      isFocused: false,

      temporaryValue: [null, null],
    };
  },
  methods: {
    onInput(value, unit) {
      const number = +value;

      if (unit === "hours") {
        if (number < 0 || number > 23) return;
      }

      if (unit == "minutes") {
        if (number < 0 || number > 59) return;
      }

      if (!this.value) {
        const tempIndex = unit == "hours" ? 0 : 1;

        this.temporaryValue[tempIndex] = number;

        if (this.temporaryValue[0] !== null && this.temporaryValue[1] !== null) {
          this.$emit(
            "input",
            moment()
              .hours(this.temporaryValue[0])
              .minutes(this.temporaryValue[1])
              .toDate()
          );
        }
      } else {
        this.$emit(
          "input",
          moment(this.value)
            .set(unit, +value)
            .toDate()
        );
      }
    },
    displayValues() {
      const safePad = (s) => {
        return s !== null ? s.toString().padStart(2, "0") : s
      };

      return {
        hours: this.value
          ? moment(this.value).format("HH")
          : safePad(this.temporaryValue[0]),
        minutes: this.value
          ? moment(this.value).format("mm")
          : safePad(this.temporaryValue[1]),
      };
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>
/* .timepicker-field {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.base-timepicker-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 8px;
}

.timepicker-field-sm {
  width: 43px;
}

.timepicker-field-md {
  width: 55px;
}

.timepicker-field-lg {
  width: 64px;
}
</style>