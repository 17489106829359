<template>
  <div class="switcher-bar">
    <div
      class="switcher-button"
      :class="getSwitcherButtonClass(tab)"
      :style="getSwitcherButtonStyles(tab)"
      v-for="(tab,index) in tabs"
      :key="index"
      @click="$emit('input', tab.value)"
    >
      <BaseIcon
        :size="iconSize"
        :icon="tab.icon"
        v-if="tab.icon"
      />
      <div>{{ tab.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    tabs: Array,
    size: {
      type: String,
      default: 'sm'
    },
    tabWidth: {
      type: String
    }
  },
  methods: {
    getSwitcherButtonClass(tab) {
      const typographyClass = {
        sm: 'md',
        md: 'lg'
      }

      const isSelected = tab.value === this.value

      return {
        [`qrw-label-${typographyClass[this.size]}`]: true,
        bold: isSelected,
        selected: isSelected,
        'with-icon': !!tab.icon,
        [this.size]: true
      }
    },
    getSwitcherButtonStyles() {
      return {
        width: this.tabWidth || 'auto'
      }
    }
  },
  computed: {
    iconSize() {
      return {
        sm: 'sm',
        md: 'sm2'
      }[this.size]
    }
  }
}
</script>

<style scoped>
.switcher-bar {
  display: flex;
  border-radius: 12px;
  padding: 4px;
  box-sizing: border-box;
}

.switcher-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--qrw-content-base-tertiary);
  cursor: pointer;
  box-sizing: border-box;
}

.selected {
  color: var(--qrw-content-base-primary);
  background: var(--qrw-background-base-primary);
  box-shadow: var(--qrw-shadow-switch-active);
}

.sm {
  border-radius: 8px;
  padding: 6px 12px;
  column-gap: 6px;
}

.sm.with-icon {
  padding: 6px 12px 6px 10px;
}

.md {
  border-radius: 10px;
  padding: 8px 16px;
  column-gap: 8px;
}

.md.with-icon {
  padding: 6px 16px 6px 14px;
}
</style>