<template>
  <div class="qrw-dots-paginator" @click="$emit('click')">
    <div class="dot" :class="{
      active: n === current
    }" v-for="n in total" :key="n"></div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 3
    },
    current: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style scoped>
.qrw-dots-paginator {
  display: flex;
  border-radius: 6px;
  align-items: center;
  box-sizing: border-box;
  padding: 4px;
  column-gap: 6px;
  background: var(--qrw-background-base-inverse-primary-02);
}

.dot {
  width: 8px;
  height: 8px;
  display: block;
  min-width: 8px;
  min-height: 8px;
  background: var(--qrw-content-base-inverse-tertiary);
  border-radius: 3px;
  transition: 0.3s;
}

.dot.active {
  background: var(--qrw-content-base-inverse-primary);
  transition: 0.3s;
}
</style>