<template>
    <div class="qrw-label-badge" :class="{
        rounded,
        'with-icon': !!icon,
        [size]: true,
        [variant]: true,
        [`qrw-label-${size}`]: true
    }" :style="badgeStyle">
        <BaseIcon :icon="icon" :size="iconSize" v-if="icon"/>
        <slot />
    </div>
</template>

<script>
import Utils from '../utils.js'

export default {
    props: {
        rounded: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'xs'
        },
        icon: {
            type: String,
            default: null
        },
        variant: {
            type: String,
            default: 'filled'
        },
        primaryColor: {
            type: String,
            default: 'background-base-inverse-primary'
        },
        secondaryColor: {
            type: String,
            default: 'content-base-inverse-primary'
        }
    },
    computed: {
        iconSize() {
            return {
                'xs': 'mxs',
                'sm': 'xs',
                'md': 'sm'
            }[this.size] || 'xs';
        },
        badgeStyle() {
            return {
                color: Utils.tokenToCssVar(this.secondaryColor),
                backgroundColor: Utils.tokenToCssVar(this.primaryColor)
            }
        }
    }
}
</script>

<style scoped>
.qrw-label-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2px;
    cursor: pointer;
}


.qrw-label-badge.xs {
    padding: 1px 6px; 
    border-radius: 3px;
}

.qrw-label-badge.sm {
    padding: 2px 6px;
    border-radius: 4px;
}

.qrw-label-badge.md {
    padding: 2px 8px;
    border-radius: 6px;
    column-gap: 4px;
}

.qrw-label-badge.lg {
    padding: 2px 8px;
    border-radius: 6px;
    column-gap: 4px;
}

.qrw-label-badge.xs.with-icon {
    padding-left: 4px;
}

.qrw-label-badge.sm.with-icon {
    padding-left: 4px;
}

.qrw-label-badge.md.with-icon {
    padding-left: 6px;
}
.qrw-label-badge.lg.with-icon {
    padding-left: 6px;
}


.qrw-label-badge.rounded {
    border-radius: 20px;
}

</style>