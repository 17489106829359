<template>
  <div class="qrw-select-container">
    <div
      class="qrw-select-control"
      :class="{
        [size]: true,
        [valueState]: true,
        'invalid': invalid
      }"
      @click="isSelecting = !isSelecting"
    >
      <div
        :class="{
          'select-text': true,
          [`qrw-paragraph-${size}`]: true,
        }"
      >
        {{ selectedValueText || placeholder }}
      </div>

      <BaseIcon :size="iconRenderOptions.size" :icon="iconRenderOptions.key" />
    </div>

    <div class="qrw-select-dropdown" :class="{[size]: true}" v-if="isSelecting">
      <div
        class="qrw-select-option"
        :class="{ [size]: true, [`qrw-paragraph-${size}`]: true }"
        v-for="(text, key) in renderableOptions"
        :key="key"
        @click="onSelectItem(key)"
      >
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Object, Boolean, Number],
      default: null,
    },
    choices: {
      type: [Array, Object],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: null,
    },
    placeholderValue: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "sm",
      validator: (v) => ["sm", "md", "lg"].includes(v),
    },
  },
  data() {
    return {
      isSelecting: false,
    };
  },
  methods: {
    onSelectItem(key) {
      this.isSelecting = false;
      this.$emit("input", key);
    },
  },
  computed: {
    valueState() {
      if (this.disabled) return "disabled";
      if (this.isSelecting) return "focused";
      if (!this.value || (this.placeholderValue !== null && this.value === this.placeholderValue)) return "placeholder";

      return "filled";
    },
    iconRenderOptions() {
      const sizes = {
        sm: "sm",
        md: "sm2",
        lg: "sm4",
      };

      return {
        size: sizes[this.size],
        key: this.isSelecting ? "chevron-up" : "chevron-down",
      };
    },
    selectedValueText() {
        if (!this.value || !this.renderableOptions[this.value]) return null;

        return this.renderableOptions[this.value];
    },
    renderableOptions() {
      if (Array.isArray(this.choices)) {
        return this.choices.reduce(
          (acc, item) => ({
            ...acc,
            [item]: item,
          }),
          {}
        );
      }

      if (typeof this.choices === "object") {
        return this.choices;
      }

      return {};
    },
  },
};
</script>

<style scoped>
.qrw-select-control {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  background: var(--qrw-background-base-primary);
}

.qrw-select-control.sm {
  padding: 5px 10px;
  column-gap: 10px;
  border-radius: 8px;
}

.qrw-select-control.md {
  padding: 7px 12px;
  column-gap: 8px;
  border-radius: 10px;
}

.qrw-select-control.lg {
  padding: 9px 16px;
  column-gap: 10px;
  border-radius: 12px;
}

.placeholder {
  color: var(--qrw-content-base-tertiary);
  border: 1px solid var(--qrw-border-base-secondary);
}

.filled {
  color: var(--qrw-content-base-primary);
  border: 1px solid var(--qrw-border-base-primary);
}

.focused {
  color: var(--qrw-content-base-secondary);
  border: 1px solid var(--qrw-border-base-primary);
}

.disabled {
  color: var(--qrw-content-base-tertiary);
  background: var(--qrw-background-base-tertiary);
}

.invalid {
    border-color: var(--qrw-border-error-primary);
}

.select-text {
  width: 100%;
}

.qrw-select-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: 4px;

  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.16);
}

.qrw-select-dropdown.sm {
    top: calc(32px + 12px);
}

.qrw-select-dropdown.md {
    top: calc(40px + 12px);
}

.qrw-select-dropdown.lg {
    top: calc(48px + 12px);
}

.qrw-select-option {
  background: var(--qrw-background-base-primary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--qrw-content-base-secondary);
}

.qrw-select-option.sm {
  padding: 4px 6px;
}

.qrw-select-option.md {
  padding: 6px 8px;
}

.qrw-select-option.lg {
  padding: 8px 12px;
}

.qrw-select-option:hover {
  background: var(--qrw-background-base-secondary);
  color: var(--qrw-content-base-primary);
}

.qrw-select-container {
  position: relative;
}
</style>