<template>
  <BaseSimpleModal :title="title || uiTranslations.title" :subtitle="subtitle || uiTranslations.subtitle"
    bodyClass="language-picker" @backdropClick="$emit('close')" @close="$emit('close')" :showClose="false">

    <!-- <div class="languages-scroll-container"> -->
    <div class="languages-padding-container">
      <BaseSearchInput v-model="search" size="md" v-if="shouldShowSearch" :placeholder="uiTranslations.search" />
      <div class="language-cards-list">
        <div class="language-card" :class="{
    selected: selected === item[1]
  }" @click="selected = item[1]" v-for="item in filteredLanguages" :key="item[1]">
          <img :src="`https://cdn.qrwaiter.com.ua/assets/flags/v2-wavy/${item[2]}.png`" class="language-card-flag">
          <div class="qrw-content-base-primary qrw-label-lg" :class="{
    bold: selected === item[1]
  }">
            {{ item[0] }}
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <template #footer>
      <BasePushButton class="language-modal-button" @click="$emit('close')" size="lg" variant="ghost"
        textColor="content-base-secondary">
        {{ uiTranslations.cancel }}
      </BasePushButton>
      <BasePushButton @click="onConfirm" class="language-modal-button" :disabled="!selected" size="lg" variant="fill"
        textColor="content-on-primary" bgColor="background-base-inverse-primary-10">
        {{ uiTranslations.confirm }}
      </BasePushButton>
    </template>
  </BaseSimpleModal>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    uiLocale: {
      type: String,
      default: 'en'
    },
    value: String,
    exclude: {
      type: Array,
      default: () => []
    },
    include: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      search: null,
      selected: null
    }
  },
  methods: {
    onConfirm () {
      this.$emit('input', this.selected)
      this.$emit('close')
    }
  },
  computed: {
    shouldShowSearch () {
      return this.allowedLanguages.length > 5
    },
    allowedLanguages () {
      let target = this.allLanguages.slice()

      if (this.include && this.include.length) {
        target = target.filter(t => this.include.includes(t[1]))
      }

      if (this.exclude && this.exclude.length) {
        target = target.filter(t => !this.exclude.includes(t[1]))
      }

      return target
    },
    filteredLanguages () {
      let target = this.allowedLanguages.slice()

      if (this.search) {
        const query = this.search.toLowerCase()

        target = target.filter(t => t.join('/').toLowerCase().includes(query))
      }

      return target
    },
    allLanguages () {
      return [
        ['English', 'en', 'united-kingdom'],
        ['Українська', 'uk', 'ukraine'],
        ['русский', 'ru', 'russia'],
        ['العربية', 'ar', 'saudi-arabia'],
        ['Հայերէն', 'hy', 'armenia'],
        ['Босански', 'bs', 'bosnia-and-herzegovina'],
        ['Български', 'bg', 'bulgaria'],
        ['Čeština', 'cs', 'czech-republic'],
        ['中文', 'zh', 'china'],
        ['Dansk', 'da', 'denmark'],
        ['Deutsch', 'de', 'germany'],
        ['Nederlands', 'nl', 'netherlands'],
        ['Eesti keel', 'et', 'estonia'],
        ['Suomi', 'fi', 'finland'],
        ['Français', 'fr', 'france'],
        ['ქართული', 'ka', 'georgia'],
        ['Gaeilge', 'ga', 'ireland'],
        ['ελληνικά', 'el', 'greece'],
        ['עִבְרִית', 'he', 'israel'],
        ['हिन्दी', 'hi', 'india'],
        ['Hrvatski', 'hr', 'croatia'],
        ['Magyar', 'hu', 'hungary'],
        ['Íslenska', 'is', 'iceland'],
        ['Bahasa Indonesia', 'id', 'indonesia'],
        ['Italiano', 'it', 'italy'],
        ['日本語', 'ja', 'japan'],
        ['Қазақ тілі', 'kk', 'kazakhstan'],
        ['한국어', 'ko', 'south-korea'],
        ['Latviešu valoda', 'lv', 'latvia'],
        ['Lietuvių kalba', 'lt', 'lithuania'],
        ['Lëtzebuergesch', 'lb', 'luxembourg'],
        ['Malti', 'mt', 'malta'],
        ['Монгол', 'mn', 'mongolia'],
        ['नेपाली', 'ne', 'nepal'],
        ['Norsk', 'no', 'norway'],
        ['فارسى', 'fa', 'iran'],
        ['Polski', 'pl', 'republic-of-poland'],
        ['Português', 'pt', 'portugal'],
        ['Limba română', 'rm', 'romania'],
        ['Slovenčina', 'sk', 'slovakia'],
        ['Español', 'es', 'spain'],
        ['Shqip', 'sq', 'albania'],
        ['Cрпски', 'sr', 'serbia'],
        ['Svenska', 'sv', 'sweden'],
        ['ภาษาไทย', 'th', 'thailand'],
        ['Tagalog', 'tl', 'philippines'],
        ['Türkçe', 'tr', 'turkey'],
        ['Tiếng việt', 'vi', 'vietnam'],
        ['O’zbek tili', 'uz', 'uzbekistan'],
        ['IsiZulu', 'zu', 'south-africa'],
        ['বাংলা', 'bn', 'bangladesh'],
        ['اردو', 'ur', 'pakistan'],
        ['தமிழ்', 'ta', 'sri-lanka']
      ]
    },
    uiTranslations () {
      const uiI18n = {
        en: {
          search: 'Search',
          title: 'Languages',
          confirm: 'Confirm',
          subtitle: 'Select the preferred language',
          cancel: 'Cancel'
        },
        ru: {
          search: 'Поиск',
          title: 'Языки',
          confirm: 'Подтвердить',
          subtitle: 'Выберите желаемый язык для интерфейса',
          cancel: 'Отмена'
        },
        uk: {
          search: 'Пошук',
          title: 'Мови',
          subtitle: 'Оберіть бажану мову для інтерфейсу',
          confirm: 'Підтвердити',
          cancel: 'Відміна'
        }
      }

      return uiI18n[this.uiLocale] || uiI18n.en
    }
  },
  mounted () {
    if (this.value) {
      this.selected = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.languages-scroll-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
}

.languages-padding-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  row-gap: 24px;
  box-sizing: border-box;
}

.language-card {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 11px 24px 11px 22px;
  border-radius: 12px;
  column-gap: 10px;
  border: 1px solid var(--qrw-border-base-tertiary);
  align-items: center;
  cursor: pointer;
}

.language-card.selected {
  border: 2px solid var(--qrw-border-base-primary);
  background: var(--qrw-background-base-primary);
  padding: 10px 24px 10px 22px;
}

.language-card-flag {
  width: 24px;
  height: 24px;
  display: block;
  max-height: 24px;
  min-width: 24px;
}

.language-modal-button {
  width: 100%;
}

.language-cards-list {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
}

@include adapt-from('mobile-large') {
  .languages-padding-container {
    padding: 16px;
  }
}

/* tablet */
@include adapt-from('tablet-small') {
  .language-cards-list {
    grid-template-columns: 1fr 1fr;
  }
}

/* desktop */
@include adapt-from('desktop-small') {
  .language-cards-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>