<template>
  <div
    class="base-phone-input"
    :class="{
      [`size-${size}`]: true,
    }"
  >
    <div
      class="country-selector"
      @click="$emit('openPicker')"
    >
      <img
        :src="countryFlagUrl"
        :style="flagStyle"
      />
      <BaseIcon
        :size="null"
        :style="{
          'font-size': `${iconSize}px`,
        }"
        icon="chevron-down"
        class="qrw-text-content-base-tertiary"
      />
    </div>
    <div class="qrw-common-input-container phone-input-container">
      <div
        :class="{
          [`qrw-paragraph-${size}`]: true,
          'qrw-text-content-base-secondary': true,
          'country-code': true,
          [size]: true
        }"
        ref="countryCodePrefix"
      >
        {{ countryEntry.code }}
      </div>
      <input
        type="text"
        inputmode="tel"
        :placeholder="maskPlaceholder"
        autocomplete="tel-national"
        class="qrw-common-input-element"
        :class="{
          [`qrw-paragraph-${size}`]: true,
          [`size-${size}`]: true,
        }"
        :style="phoneInputStyles"
        v-model="internalValue"
        v-mask="countryEntry.mask"
      />
    </div>
  </div>
</template>

<script>
import PhoneCodes from '@/assets/phone_codes.json'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'sm'
    },
    defaultCountry: {
      type: String,
      default: 'ua'
    }
  },
  data() {
    return {
      selectedCountry: 'UA',

      codePrefixWidth: 0,

      internalValue: null
    }
  },
  watch: {
    internalValue() {
      if (this.internalValue && this.internalValue.length === this.countryEntry.mask.length) {
        const fullPhoneNumber = `${this.countryEntry.code}${this.internalValue}`

        const rawPhoneNumber = this.removeMaskSymbols(fullPhoneNumber)

        this.$emit('filled', rawPhoneNumber)
      }
    },
    defaultCountry() {
      this.selectedCountry = this.defaultCountry
    },
    selectedCountry() {
      this.$nextTick(() => {
        this.updatePrefixWidth()
      })
    }
  },
  methods: {
    checkInitialValue(value) {
      if (!value) return ''

      const applicableCountry = PhoneCodes.find(
        pc => value.startsWith(pc.code) && value.length === pc.code.length + this.removeMaskSymbols(pc.mask).length
      )

      console.log(value)

      if (applicableCountry) {
        this.selectedCountry = applicableCountry.iso
        return value.substring(applicableCountry.code.length)
      }
    },
    removeMaskSymbols(masked) {
      return masked.replace(/[()_-]/gi, '')
    },
    updatePrefixWidth() {
      this.codePrefixWidth = this.$refs.countryCodePrefix.offsetWidth
    }
  },
  computed: {
    iconSize() {
      return {
        sm: 16,
        md: 20,
        lg: 24
      }[this.size]
    },
    flagStyle() {
      return {
        width: `${this.iconSize}px`,
        height: `${this.iconSize}px`
      }
    },
    countryEntry() {
      return PhoneCodes.find(it => it.iso.toUpperCase() === this.selectedCountry.toUpperCase())
    },
    countryFlagUrl() {
      const convertCountryNameToImageName = name => {
        return name.toLowerCase().replace(/\s/gi, '-')
      }

      return `https://cdn.qrwaiter.com.ua/assets/flags/v2-wavy/${convertCountryNameToImageName(
        this.countryEntry.name
      )}.png`
    },
    maskPlaceholder() {
      const maskData = this.countryEntry.mask.length ? this.countryEntry.mask[0] : this.countryEntry.mask

      return maskData.replace(/#/g, '_')
    },
    phoneInputStyles() {
      const paddingAdjustments = {
        sm: 10,
        md: 12,
        lg: 16
      }

      return {
        'padding-left': `${
          (this.codePrefixWidth || this.countryEntry.code.length * 8) + paddingAdjustments[this.size]
        }px`
      }
    }
  },
  mounted() {
    if (!this.value) {
      this.selectedCountry = this.defaultCountry
    }

    this.internalValue = this.checkInitialValue(this.value)

    this.$nextTick(() => {
      this.updatePrefixWidth()
    })
  }
}
</script>

<style scoped>
.base-phone-input {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background: var(--qrw-background-base-tertiary);
}

.country-selector {
  display: flex;
  column-gap: 2px;
  align-items: center;
  justify-content: center;
}

.country-selector img {
  width: 26px;
  height: auto;
  max-width: 26px;
}

.base-phone-input.size-sm .country-selector {
  border-radius: 8px;
  padding: 5px;
}
.base-phone-input.size-md .country-selector {
  border-radius: 10px;
  padding: 7px;
}
.base-phone-input.size-lg .country-selector {
  border-radius: 12px;
  padding: 9px;
}

.phone-input-container {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.country-code {
  position: absolute;
}

.country-code.sm {
  left: 8px;
}

.country-code.md {
  left: 10px;
}

.country-code.lg {
  left: 12px;
}
</style>