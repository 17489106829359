import { render, staticRenderFns } from "./BaseSwitcherBar.vue?vue&type=template&id=208b8c70&scoped=true&"
import script from "./BaseSwitcherBar.vue?vue&type=script&lang=js&"
export * from "./BaseSwitcherBar.vue?vue&type=script&lang=js&"
import style0 from "./BaseSwitcherBar.vue?vue&type=style&index=0&id=208b8c70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "208b8c70",
  null
  
)

export default component.exports