<template>
  <div class="base-datepicker-input-wrapper">
    <div
      class="base-datepicker-input"
      ref="container"
    >
      <BaseIconInput
        :value="inputDisplayValue"
        :class="inputClass"
        :disabled="disabled"
        :placeholder="placeholder"
        @focus="showDatepicker"
        :valid="valid"
        :size="inputSize"
        readonly
        icon="calendar"
      />

      <div
        class="qrw-box-shadow-tooltip datepicker-layer"
        :style="datepickerPositionStyle"
        v-show="datepickerShown"
      >
        <div class="overlay-header">
          <div class="qrw-heading-xs bold qrw-text-content-base-primary">
            {{ $dtr.select }}
          </div>
          <div class="qrw-label-md qrw-text-content-base-secondary">
            {{ formattedOverlayTitle }}
          </div>
        </div>
        <BaseDatepicker
          @titleClick="changeUnit"
          :value="value"
          :size="pickerSize"
          :ranged="ranged"
          :locale="locale"
          :fixedWidth="pickerFixedWidth"
          class="datepicker-container"
          :unit="datepickerUnit"
          @input="(v) => $emit('input', v)"
        />
        <div
          class="overlay-footer"
          v-if="datepickerUnit != 'day'"
        >
          <BasePushButton
            class="w-100"
            size="md"
            variant="ghost"
            textColor="content-base-tertiary"
            icon="chevron-left"
            @click="datepickerUnit = 'day'"
            v-if="datepickerUnit == 'month'"
          >
            {{ $dtr.back_to_days }}
          </BasePushButton>
          <BasePushButton
            class="w-100"
            size="md"
            variant="ghost"
            textColor="content-base-tertiary"
            icon="chevron-left"
            @click="datepickerUnit = 'month'"
            v-if="datepickerUnit == 'year'"
          >
            {{ $dtr.back_to_months }}
          </BasePushButton>
        </div>
      </div>
    </div>
    <div
      @click="hideDatepicker"
      class="datepicker-backdrop"
      v-if="datepickerShown"
    ></div>
  </div>
</template>

<script>
import moment from 'moment'

import BaseIconInput from '@/components/inputs/BaseIconInput.vue'

export default {
  props: {
    value: [Object, String, Date, Number],
    ranged: Boolean,
    disabled: Boolean,
    inputClass: String,
    placeholder: String,
    inputSize: { type: String, default: 'sm' },
    pickerSize: { type: String, default: 'md' },
    pickerFixedWidth: { type: Boolean, default: false },
    valid: {
      type: Boolean,
      default: true
    },
    locale: String,
    size: { type: String, default: 'sm' }
  },
  data() {
    return {
      datepickerShown: false,
      datepickerUnit: 'day',

      fixedX: 0,
      fixedY: 0,
      fixedWidth: 0
    }
  },
  methods: {
    showDatepicker() {
      const rect = this.$refs.container.getBoundingClientRect()

      this.fixedX = rect.left
      this.fixedY = rect.top + rect.height
      this.fixedWidth = rect.width

      this.$nextTick(() => {
        this.datepickerShown = true
      })

      window.addEventListener('scroll', this.hideDatepicker)
    },
    hideDatepicker() {
      this.datepickerShown = false
      window.removeEventListener('scroll', this.hideDatepicker)
    },
    changeUnit() {
      if (this.datepickerUnit == 'day') {
        this.datepickerUnit = 'month'
      } else if (this.datepickerUnit == 'month') {
        this.datepickerUnit = 'year'
      }
    },
    formatDisplayValue(fmt) {
      if (!this.value) return null

      if (this.ranged) {
        if (!this.value) return null

        let fmt = ''

        if (this.value.start) {
          fmt += moment(this.value.start).format(fmt)
        }

        if (this.value.end) {
          fmt += ` - ${moment(this.value.end).format(fmt)}`
        }

        return fmt
      } else {
        return moment(this.value).format(fmt)
      }
    }
  },
  computed: {
    inputDisplayValue() {
      return this.formatDisplayValue('DD.MM.YYYY')
    },
    datepickerPositionStyle() {
      return {
        left: `${this.fixedX}px`,
        top: `${this.fixedY}px`,
        width: this.pickerFixedWidth ? '360px' : `${this.fixedWidth}px`
      }
    },
    $dtr() {
      return {
        en: {
          select: 'Select date',
          back_to_days: 'Back to days',
          back_to_months: 'Back to months'
        },
        ru: {
          select: 'Выберите дату',
          back_to_days: 'Назад к дням',
          back_to_months: 'Назад к месяцам'
        },
        uk: {
          select: 'Оберіть дату',
          back_to_days: 'Повернутись до днів',
          back_to_months: 'Повернутись до місяців'
        }
      }[this.locale || 'en']
    },
    formattedOverlayTitle() {
      if (this.datepickerUnit == 'day') {
        return this.inputDisplayValue
      } else if (this.datepickerUnit == 'month') {
        return this.formatDisplayValue('MMMM YYYY')
      } else {
        return this.formatDisplayValue('YYYY')
      }
    }
  },
  components: {
    BaseIconInput
  }
}
</script>

<style scoped>
.datepicker-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;

  background: transparent;
  left: 0;
  top: 0;

  z-index: 50;
}

.datepicker-layer {
  position: fixed;
  z-index: 55;
  align-self: center;
  justify-self: center;
  display: flex;
  border-radius: 16px;
  overflow: hidden;

  transition: opacity 0.5s linear;

  flex-direction: column;

  width: 360px;
}

.base-datepicker-input {
  display: flex;
  position: relative;
  align-items: center;
}

.datepicker-container {
  padding: 16px;
  box-sizing: border-box;
}

.overlay-header,
.overlay-footer {
  background: var(--qrw-background-base-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
}
</style>