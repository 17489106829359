<template>
  <div
    class="base-tabs-wrapper"
    :style="{
      'border-radius': cssBorderRadius,
    }"
  >
    <div class="base-tabs" :style="tabsContainerStyle">
      <div class="tab-wrapper" :class="{
        'qrw-box-shadow-card-light': index === currentTabIndex
      }" v-for="(tab, index) in tabs" :key="index">
        <span class="left-tab-ear" :style="{
          color: earSvgColor,
          height: `${earSvgSize}px`,
          left: `-${earSvgSize}px`
        }" v-html="earSvgs[0]" v-if="index === currentTabIndex && index > 0"/>

        <div
          class="tab"
          :class="{
            [`qrw-label-${mappedTextSize}`]: true,
            bold: currentTabIndex === index,
          }"
          :style="getTabStyle(tab, index)"
          @click="$emit('input', tab.value)"
        >
          {{ tab.text }}
        </div>

         <span class="right-tab-ear" :style="{
          color: earSvgColor,
          height: `${earSvgSize}px`,
          right: `-${earSvgSize}px`
        }" v-html="earSvgs[1]" v-if="index === currentTabIndex && index < tabs.length-1"/>
      </div>
    </div>

    <div
      class="tabs-content"
      :class="contentClass"
      :style="contentBlockStyle"
      v-if="hasContentSlot"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import utils from "../utils";

// Please note, we must add 'display: block' to each svg so it renders properly
const EarSvgs = {
  sm: [
    `<svg style="display: block" width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 0V5.99878H0C3.32502 5.93368 6 3.27286 6 0Z" fill="currentColor"/>
</svg>
`,
    `<svg style="display: block" width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V6H6C2.68629 6 0 3.31371 0 0Z" fill="currentColor"/>
</svg>
`,
  ],
  md: [
    `<svg style="display: block" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 0V8H0C4.41828 8 8 4.41828 8 0Z" fill="currentColor"/>
</svg>
`,
    `<svg style="display: block" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V8H8C3.58172 8 0 4.41828 0 0Z" fill="currentColor"/>
</svg>
`,
  ],
};

export default {
  props: {
    tabs: Array,
    value: [Number, String, Boolean],
    size: {
      type: String,
      default: "sm",
      validator: (v) => ["sm", "md"].includes(v),
    },
    contentClass: {
      type: String,
      default: null,
    },
    selectedColor: {
      type: String,
      default: "background-base-primary",
    },
    defaultColor: {
      type: String,
      default: "background-base-tertiary",
    },
    defaultTextColor: {
      type: String,
      default: "content-base-secondary",
    },
    selectedTextColor: {
      type: String,
      default: "content-base-primary",
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    noCorners: {
      type: Boolean,
      default: false,
    },
    waterfall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    getTabStyle(tab, index) {
      const style = {};

      const paddingsMap = {
        sm: "8px",
        md: "10px",
      };

      style.padding = paddingsMap[this.size];

      if (this.currentTabIndex === index) {
        //Current tab has colors, selected background, shadow and border-radius
        style.color = utils.tokenToCssVar(this.selectedTextColor);
        style.background = utils.tokenToCssVar(this.selectedColor);
        style.borderRadius = `${this.cssBorderRadius} ${this.cssBorderRadius} 0 0`;
      } else {
        //Default tab has only text color (bg color deriven from container)
        style.color = utils.tokenToCssVar(this.defaultTextColor);
      }

      return style;
    },
  },
  computed: {
    currentTabIndex() {
      return this.tabs.findIndex((t) => t.value == this.value);
    },
    cssBorderRadius() {
      return (
        {
          sm: "6px",
          md: "8px",
        }[this.size] || "6px"
      );
    },
    contentBlockStyle() {
      return {};
    },
    hasContentSlot() {
      return !!this.$slots.default;
    },
    mappedTextSize() {
      return {
        sm: "sm",
        md: "md",
      }[this.size];
    },
    tabsContainerStyle() {
      return {
        background: utils.tokenToCssVar(this.defaultColor),
      };
    },
    earSvgs() {
      return EarSvgs[this.size];
    },
    earSvgColor() {
      return utils.tokenToCssVar(this.selectedColor);
    },
    earSvgSize() {
      return {
        sm: 6,
        md: 8
      }[this.size];
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.base-tabs-wrapper {
  display: flex;
  width: 100%;

  flex-direction: column;
}

.tab-wrapper {
  box-sizing: border-box;

  display: flex;

  position: relative;

  width: 100%;
}

.base-tabs {
  display: flex;
  flex-direction: row;
}

.tab {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.left-tab-ear, .right-tab-ear {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  display: block;
  z-index: 5;
}
</style>