const GlobalIconsLibrary = window.QRW_UIKIT_ICONS || {};

const promises = {};

function compileIcon(raw) {
  let svg = raw.replace(/fill=".*"/g, '');

  const svg_pos = svg.indexOf('svg');

  svg =
    svg.substring(0, svg_pos + 3) +
    ' fill="currentColor"' +
    svg.substring(svg_pos + 3);

  svg = svg.replace(/="24"/g, '="1em"');

  return svg;
}

async function loadOrWaitForIcon(icon) {
  const iconPath = `https://cdn.qrwaiter.com.ua/assets/icons/${icon}.svg`;

  if (!icon) {
    return Promise.resolve(null);
  }

  if (GlobalIconsLibrary[icon]) {
    return Promise.resolve(GlobalIconsLibrary[icon]);
  }

  const promise =
    promises[icon] || fetch(iconPath).then((response) => response.text());

  promises[icon] = promise;

  return promise.then((svg) => {
    if (!svg.startsWith('<svg')) return null;
    if (!GlobalIconsLibrary[icon]) GlobalIconsLibrary[icon] = compileIcon(svg);

    console.log(`[QRW UI] Loaded icon '${icon}'`);

    return GlobalIconsLibrary[icon];
  });
}

async function preloadIcons(list) {
  for (const icon of list) {
    loadOrWaitForIcon(icon);
  }
}

function tryGetIconFromCache(icon) {
  return GlobalIconsLibrary[icon] || null;
}

export default {
  loadOrWaitForIcon,
  preloadIcons,
  tryGetIconFromCache,
};
