<template>
  <div class="qrw-header-wrapper">
    <div class="qrw-header-title gap-column-xs">
      <h5 class="qrw-heading-sm">
        <slot name="title"></slot>
      </h5>
      <div class="qrw-header-actions" v-if="$slots.actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <!-- <hr
      :class="{ 'no-line': noline }"
      :style="{
        'border-top': `1px solid var(--${color}-color)`,
      }"
    /> -->
    <BaseDivider :color="color"/>
  </div>
</template>

<script>
export default {
  props: {
    noline: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "grey-10",
    },
  },
};
</script>

<style scoped>
.qrw-header-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.qrw-header-title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  row-gap: 8px;
}

.qrw-header-title h5 {
  width: inherit;
}

.qrw-header-actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: center;
  min-width: fit-content;
}

h5 {
  line-height: inherit;
}

hr {
  margin: 4px 0 0;
  border-bottom: 0;
}

hr.no-line {
  display: none;
}
</style>