<template>
  <div class="base-badge qrw-label-sm" :class="{[variant]: true, round}" :style="badgeStyle" @click="$emit('click')">
      <BaseIcon size="mxs" :icon="icon" v-if="icon"/>
      <slot />
  </div>
</template>

<script>
import Utils from '../utils'

export default {
    props: {
        color: {
            type: String,
            default: 'background-error-primary'
        },
        icon: {
            type: String
        },
        round: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        badgeStyle() {
            return {
                'background-color': `var(--${Utils.getVariableForColorName(this.color)})`
            }
        }
    }
}
</script>

<style scoped>
.base-badge {
    padding: 2px 6px;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    column-gap: 4px;

    border-radius: 4px;

    width: fit-content;
    height: fit-content;
    white-space: nowrap;

    cursor: pointer;

    align-items: center;
}

.base-badge.primary {
    color: var(--qrw-content-base-inverse-primary);
}

.base-badge.secondary {
    color: var(--qrw-content-base-primary);
}

.base-badge.round {
    border-radius: 20px;
}
</style>