<template>
  <div class="base-textarea-container">
    <textarea
      :rows="rows"
      :disabled="disabled || readonly"
      :required="required"
      :class="{
        [`qrw-paragraph-${size}`]: true,
        [size]: true,
        'state-disabled': disabled,
        'state-error': !valid,
        'webkit-color-fix': value && disabled,
        resizable: autoresize,
      }"
      :placeholder="placeholder"
      ref="textareaElement"
      @input="onTextareaInput"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :value="value"
      :maxlength="maxlength"
    ></textarea>

    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="textarea-resize-ear"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1923 13C19.6384 13 20 13.3616 20 13.8077V14.8846C20 17.7098 17.7098 20 14.8846 20H13.8077C13.3616 20 13 19.6384 13 19.1923C13 18.7462 13.3616 18.3846 13.8077 18.3846H14.8846C16.8176 18.3846 18.3846 16.8176 18.3846 14.8846V13.8077C18.3846 13.3616 18.7462 13 19.1923 13Z"
        fill="black"
        fill-opacity="0.4"
      />
    </svg>

  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    readonly: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    rows: {
      type: Number,
      default: 3
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'md'
    },
    autoresize: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 16384
    }
  },
  methods: {
    getNativeElement() {
      return this.$refs.textareaElement
    },
    onTextareaInput(ev) {
      this.$emit('input', ev.target.value)

      if (this.autoresize) {
        this.autoresizeCheck()
      }
    },
    autoresizeCheck() {
      this.getNativeElement().style.height = 'auto'
      this.getNativeElement().style.height = `${this.getNativeElement().scrollHeight}px`
    }
  },
  mounted() {
    if (this.autoresize) {
      this.autoresizeCheck()
    }
  }
}
</script>

<style scoped>
textarea {
  -webkit-appearance: none;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  resize: none;
  padding: 7px;
  border: 1px solid var(--qrw-border-base-secondary);
  color: var(--qrw-content-base-primary);
  background: var(--qrw-background-base-primary);
}

textarea.state-disabled {
  cursor: not-allowed;
  border: none;
  color: var(--qrw-content-base-tertiary);
  background: var(--qrw-background-base-tertiary);
}

textarea.state-error {
  border: 1px solid var(--red-6-color);
}

textarea:focus {
  border: 1px solid var(--qrw-border-base-primary);
}

textarea.webkit-color-fix {
  -webkit-text-fill-color: var(--qrw-content-base-primary);
}

textarea.sm {
  padding: 5px 10px;
  border-radius: 8px;
}

textarea.md {
  padding: 7px 12px;
  border-radius: 10px;
}

textarea.lg {
  padding: 9px 16px;
  border-radius: 12px;
}

textarea.sm.disabled {
  padding: 6px 10px;
}

textarea.md.disabled {
  padding: 8px 12px;
}

textarea.lg.disabled {
  padding: 10px 16px;
}

textarea.resizable {
  /* resize: vertical; */
}

/* Placeholder */
textarea::placeholder,
textarea:disabled::placeholder,
textarea::-webkit-input-placeholder {
  /* color for placeholders are the same for enabled and disabled input */
  color: var(--qrw-content-base-tertiary) !important;
}

.base-textarea-container {
  position: relative;
}

.textarea-resize-ear {
  position: absolute;
  bottom: 4px;
  right: 0px;
}
</style>