<template>
  <img
    :src="iconSvg"
    alt="icon"
    class="icon"
    v-if="outline"
  />
  <span
    @click="$emit('click')"
    v-html="iconSvg"
    :style="{
      'vertical-align': verticalAlign,
    }"
    :class="iconSize"
    :alt="correctedIconName+'-'+size"
    v-else
  >
  </span>
</template>

<script>
import IconNames from '@/assets/icons.json'

import IconsLoader from '@/iconsLoader.js'

export default {
  props: {
    outline: {
      type: Boolean,
      default: false
    },
    balloon: {
      type: [Boolean, String],
      default: false
    },
    size: {
      type: String,
      default: 'xs'
    },
    type: {
      type: String,
      default: 'buttons'
    },
    verticalAlign: {
      type: String,
      default: 'middle'
    },
    icon: String
  },
  data() {
    return {
      fetched: false
    }
  },
  methods: {
    fetchIcon() {
      const cached = IconsLoader.tryGetIconFromCache(this.correctedIconName)

      if (cached) {
        this.fetched = cached
        return
      }

      IconsLoader.loadOrWaitForIcon(this.correctedIconName).then(icon => {
        this.fetched = icon
      })
    }
  },
  watch: {
    icon() {
      this.fetchIcon()
    }
  },
  computed: {
    iconSvg() {
      if (!this.fetched) return null
      if (this.outline) return `/icons/${this.type}/${this.correctedIconName}-outlined.svg`

      const svg = this.fetched

      let html = svg

      if (this.balloon) {
        const balloonColor = typeof this.balloon === 'boolean' ? 'red-4' : this.balloon
        html += `<div class="base-icon-balloon" style="background: var(--${balloonColor}-color);"></div>`
      }

      return html
    },
    iconSize() {
      return `qrw-base-icon size-${this.size}`
    },
    exceptions() {
      return [
        'checker-toggle-off',
        'checker-toggle-on',
        'square-checker-on',
        'square-checker-off',
        'loader-1',
        'loader-2',
        'loader-3',
        'loader-4',
        'loader-5',
        'loader-6',
        'loader-7',
        'loader-8'
      ]
    },
    correctedIconName() {
      if (!IconNames) return null
      if (this.exceptions.includes(this.icon)) return this.icon

      if (!this.icon.includes('regular') && !this.icon.includes('bold')) {
        if (IconNames[this.icon]) return this.icon
        return `${this.icon.toLowerCase()}-regular`
      } else {
        return this.icon.toLowerCase()
      }

      // return this.icon.toLowerCase();
    }
  },
  created() {
    this.fetchIcon()
  }
}
</script>

<style>
.base-icon-balloon {
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  min-width: 6px;

  border-radius: 50%;
  box-sizing: border-box;
  display: block;

  position: absolute;

  top: -2px;
  right: -2px;
}

.size-sm2 .base-icon-balloon {
  top: -2px;
  right: -2px;
}

.size-sm .base-icon-balloon {
  top: -2px;
  right: -2px;
}
</style>

<style scoped>
span {
  display: inline-flex;
  position: relative;
}
.size-xxs {
  font-size: 6px;
}
.size-1xs {
  font-size: 12px;
}
.size-mxs {
  font-size: 14px;
}
.size-xs {
  font-size: 16px;
}
.size-sxs {
  font-size: 18px;
}
.size-sm {
  font-size: 20px;
}
.size-msm2 {
  font-size: 22px;
}
.size-sm2 {
  font-size: 24px;
}
.size-sm3 {
  font-size: 26px;
}
.size-sm4 {
  font-size: 28px;
}
.size-xmd {
  font-size: 30px;
}
.size-md {
  font-size: 32px;
}
.size-md2 {
  font-size: 36px;
}
.size-lg {
  font-size: 40px;
}

img.icon {
  width: 16px;
  height: 16px;
}
</style>