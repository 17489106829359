<template>
  <div class="qrw-simple-modal-header">
    <div class="modal-header-titles">
      <div
        class="qrw-heading-xs bold qrw-text-content-base-primary"
        v-if="title"
      >
        {{ title }}
      </div>
      <div
        class="qrw-label-sm qrw-text-content-base-secondary"
        v-if="subtitle"
      >
        {{ subtitle }}
      </div>
      <slot name="subheader" />
    </div>
    <BaseIconButton
      icon="x-close"
      variant="ghost"
      size="md"
      textColor="content-base-secondary"
      @click="$emit('closeClicked')"
      v-if="showClose"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    showClose: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.qrw-simple-modal-header {
  padding: 4px 12px;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  background: var(--qrw-background-base-secondary-08);
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  align-items: flex-start;
  border-bottom: 1px solid var(--qrw-border-base-tertiary);
}

.modal-header-titles {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 0;
}

@include adapt-from('tablet-small') {
  .qrw-simple-modal-header {
    padding: 4px 16px;
  }
}
</style>