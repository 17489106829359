import './css/typography.css';
import './css/colors.scss';

import BaseButton from './components/buttons/BaseButton.vue';
import BasePushButton from './components/buttons/BasePushButton.vue';
import BaseIconButton from './components/buttons/BaseIconButton.vue';
import BaseSelectButton from './components/buttons/BaseSelectButton.vue';
import BasePulldownButton from './components/buttons/BasePulldownButton.vue';

import BaseUnitInput from './components/inputs/BaseUnitInput.vue';
import BaseRangeInput from './components/inputs/BaseRangeInput.vue';
import PhoneInput from './components/inputs/PhoneInput.vue';
import BaseDatepickerInput from './components/inputs/BaseDatepickerInput.vue';
import BaseTimepickerInput from './components/inputs/BaseTimepickerInput.vue';
import BaseInput from './components/inputs/BaseInput.vue';
import BaseTextarea from './components/inputs/BaseTextarea.vue';
import BaseTextInput from './components/inputs/BaseTextInput.vue';
import BaseNumberInput from './components/inputs/BaseNumberInput.vue';
import BaseSearchInput from './components/inputs/BaseSearchInput.vue';

import BaseRadioButton from './components/BaseRadioButton.vue';
import BaseCheckbox from './components/BaseCheckbox.vue';
import BaseBetaCheckbox from './components/controls/BaseBetaCheckbox.vue';
import BaseBetaRadioButton from './components/controls/BaseBetaRadioButton.vue';
import BaseTabButton from './components/buttons/BaseTabButton.vue';
import BaseBetaToggle from './components/controls/BaseBetaToggle.vue';

import BaseDrumTimepicker from './components/highlevel/BaseDrumTimepicker.vue';

import BaseHeader from './components/BaseHeader.vue';
import BaseTab from './components/BaseTab.vue';
import BaseBetaTab from './components/BaseBetaTab.vue';
import BaseTabSwitcher from './components/BaseTabSwitcher.vue';
import BaseTextDivider from './components/BaseTextDivider.vue';
import BaseIcon from './components/BaseIcon.vue';
import BaseToggleSwitcher from './components/BaseToggleSwitcher.vue';
import HorizontalScroll from './components/HorizontalScroll.vue';
import BaseDivider from './components/BaseDivider.vue';
import BaseSelect from './components/BaseSelect.vue';
import BaseBetaSelect from './components/BaseBetaSelect.vue';
import LanguageSwitcher from './components/LanguageSwitcher.vue';
import BaseUnitSelect from './components/BaseUnitSelect.vue';
import BaseSmartScroller from './components/BaseSmartScroller.vue';
import BaseBadge from './components/BaseBadge.vue';
import BaseTag from './components/BaseTag.vue';
import BaseDatepicker from './components/BaseDatepicker.vue';
import BasePhoneInput from './components/inputs/BasePhoneInput.vue';
import BaseCounter from './components/controls/BaseCounter.vue';
import BasePhoneInputPicker from './components/inputs/BasePhoneInputPicker.vue';
import BaseSimpleModal from './components/modals/BaseSimpleModal.vue';
import BaseSimpleModalHeader from './components/modals/BaseSimpleModalHeader.vue';
import BaseLanguagePicker from './components/highlevel/BaseLanguagePicker.vue';
import BaseProgressBar from './components/controls/BaseProgressBar.vue';
import BaseLoaderIcon from './components/BaseLoaderIcon.vue';

import BaseLabelBadge from './components/BaseLabelBadge.vue';
import BaseIconBadge from './components/BaseIconBadge.vue';
import BaseDotsPaginator from './components/controls/BaseDotsPaginator.vue';
import BaseWorkspaceAvatar from './components/BaseWorkspaceAvatar.vue';
import BaseSwitcherBar from './components/controls/BaseSwitcherBar.vue';

import BaseDigitInputsGroup from './components/inputs/BaseDigitInputsGroup.vue';

import VueMask from 'v-mask';

import IconsLoader from './iconsLoader';

const QRWaiterUIKit = {
  install(Vue, _options) {
    const options = _options || {};

    Vue.use(VueMask, {
      placeholders: {
        '(': null,
        ')': null,
        '-': null,
      },
    });

    window.QRW_UIKIT_ICONS = {};

    if (options.preloadIcons) IconsLoader.preloadIcons(options.preloadIcons);

    if (options.disableGlobalComponents) return;

    Vue.component('BaseButton', BaseButton);
    Vue.component('BasePushButton', BasePushButton);
    Vue.component('BaseIconButton', BaseIconButton);
    Vue.component('BaseSelectButton', BaseSelectButton);
    Vue.component('BasePulldownButton', BasePulldownButton);

    Vue.component('BaseUnitInput', BaseUnitInput);
    Vue.component('BaseInput', BaseInput);
    Vue.component('BaseDatepickerInput', BaseDatepickerInput);
    Vue.component('BaseTimepickerInput', BaseTimepickerInput);
    Vue.component('BaseRangeInput', BaseRangeInput);
    Vue.component('PhoneInput', PhoneInput);
    Vue.component('BaseTextInput', BaseTextInput);
    Vue.component('BaseNumberInput', BaseNumberInput);
    Vue.component('BaseSearchInput', BaseSearchInput);
    Vue.component('BasePhoneInput', BasePhoneInput);
    Vue.component('BasePhoneInputPicker', BasePhoneInputPicker);
    Vue.component('BaseWorkspaceAvatar', BaseWorkspaceAvatar);
    Vue.component('BaseDigitInputsGroup', BaseDigitInputsGroup);

    Vue.component('BaseDrumTimepicker', BaseDrumTimepicker);
    Vue.component('BaseSimpleModalHeader', BaseSimpleModalHeader);

    Vue.component('BaseCheckbox', BaseCheckbox);
    Vue.component('BaseBetaCheckbox', BaseBetaCheckbox);
    Vue.component('BaseBetaRadioButton', BaseBetaRadioButton);
    Vue.component('BaseTabButton', BaseTabButton);

    Vue.component('BaseDatepicker', BaseDatepicker);
    Vue.component('BaseRadioButton', BaseRadioButton);
    Vue.component('BaseHeader', BaseHeader);
    Vue.component('BaseTab', BaseTab);
    Vue.component('BaseTabSwitcher', BaseTabSwitcher);
    Vue.component('BaseTextDivider', BaseTextDivider);
    Vue.component('BaseIcon', BaseIcon);
    Vue.component('BaseToggleSwitcher', BaseToggleSwitcher);
    Vue.component('HorizontalScroll', HorizontalScroll);
    Vue.component('BaseDivider', BaseDivider);
    Vue.component('BaseTextarea', BaseTextarea);
    Vue.component('BaseSelect', BaseSelect);
    Vue.component('LanguageSwitcher', LanguageSwitcher);
    Vue.component('BaseUnitSelect', BaseUnitSelect);
    Vue.component('BaseSmartScroller', BaseSmartScroller);
    Vue.component('BaseBadge', BaseBadge);
    Vue.component('BaseTag', BaseTag);
    Vue.component('BaseSwitcherBar', BaseSwitcherBar);

    Vue.component('BaseBetaSelect', BaseBetaSelect);
    Vue.component('BaseCounter', BaseCounter);
    Vue.component('BaseProgressBar', BaseProgressBar);
    Vue.component('BaseBetaToggle', BaseBetaToggle);

    Vue.component('BaseDotsPaginator', BaseDotsPaginator);

    Vue.component('BaseBetaTab', BaseBetaTab);

    Vue.component('BaseLoaderIcon', BaseLoaderIcon);

    Vue.component('BaseSimpleModal', BaseSimpleModal);
    Vue.component('BaseLanguagePicker', BaseLanguagePicker);

    Vue.component('BaseLabelBadge', BaseLabelBadge);
    Vue.component('BaseIconBadge', BaseIconBadge);

    Vue.mixin({
      computed: {
        isMobile() {
          return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        },
      },
    });
  },
};

export default QRWaiterUIKit;
