<template>
  <div class="circle-switcher-container">
    <div
      :class="{
        'circle-switcher-box': true,
        large: size === 'lg',
        'circle-switcher-box-active': !!this.value,
        disabled: this.disabled,
      }"
      :style="customizedStyle"
      @click="onToggle"
    >
      <div
        :style="
          !value
            ? `background-color: var(--${fillCircle}-color)`
            : `background-color: var(--${enabledCircle}-color)`
        "
        :class="[
          value ? 'circle-switcher active' : 'circle-switcher',
          size == 'lg' ? 'large' : '',
        ]"
      ></div>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils.js";

export default {
  props: {
    value: [String, Number, Boolean],
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    disabledBg: {
      type: String,
      default: "grey-10",
    },
    fillBg: {
      type: String,
      default: "grey-10",
    },
    fillCircle: {
      type: String,
      default: "grey-10",
    },
    enabledCircle: {
      type: String,
      default: "white",
    },
  },
  methods: {
    onToggle() {
      if (this.disabled) return;
      this.$emit("input", !this.value);
    },
  },
  computed: {
    customizedStyle() {
      // if (!this.fillBg) return "";
      return this.value
        ? `background-color: var(--${Utils.getVariableForColorName(
            this.fillBg
          )}); border:1px solid var(--${Utils.getVariableForColorName(
            this.fillBg
          )})`
        : `border:1px solid var(--${Utils.getVariableForColorName(
            this.disabledBg
          )})`;
    },
  },
};
</script>

<style scoped>
.circle-switcher-container {
  display: flex;
  align-content: center;
  align-items: center;
}

.circle-switcher-box {
  background: transparent;
  width: 54px;
  height: 22px;
  /* border: 1px solid var(--qrw-border-base-primary); */
  border-radius: 50px;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.circle-switcher {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--grey-10-color);
  transition: 0.2s;
  z-index: 1;
  position: relative;
  left: 1px;
}

.circle-switcher-box.large {
  height: 30px;
}

.circle-switcher.large {
  width: 28px;
  height: 28px;
}

.circle-switcher.active {
  background: var(--white-color);
}

.circle-switcher.active.large {
  left: 25px;
}

.circle-switcher-box-active {
  background: var(--grey-10-color);
  transition: 0.2s;
}

.circle-switcher.active {
  left: 33px;
  transition: 0.2s;
}
.disabled {
  opacity: 0.64;
  cursor: not-allowed;
}
</style>