<template>
  <div
    class="base-datepicker"
    :class="{ 'fixed-width': fixedWidth }"
  >
    <div
      class="datepicker-title"
      v-if="unit != 'year'"
    >
      <BaseIconButton
        @click="changeMonth(-1)"
        variant="ghost"
        textColor="content-base-tertiary"
        size="sm"
        icon="arrow-left"
      />
      <div
        @click="$emit('titleClick')"
        class="datepicker-current-date qrw-text-content-base-secondary bold"
      >
        {{ formattedDatepickerTitle }}
      </div>
      <BaseIconButton
        @click="changeMonth(1)"
        variant="ghost"
        textColor="content-base-tertiary"
        size="sm"
        icon="arrow-right"
      />
    </div>

    <div
      class="datepicker-weekdays"
      :class="{
        [size]: true,
      }"
      v-if="unit == 'day'"
    >
      <div
        v-for="day in weekdayNames"
        :key="day"
        class="weekday-name qrw-paragraph-xs bold qrw-text-content-base-tertiary"
        :class="{
          [size]: true,
        }"
      >
        {{ day }}
      </div>
    </div>

    <div class="datepicker-calendar">
      <div
        class="datepicker-cell"
        :class="{
          disabled: !cell.isCurrentMonth || cell.isOutOfAllowedRange,
          'selected bold': cell.isSelected,
          ranged: cell.isRanged,
          today: cell.isToday,
          [size]: true,
          [`grid-cell-${unit}`]: true
        }"
        @click="onCellClicked(cell, index)"
        v-for="(cell, index) in uiCells"
        :key="index"
      >
        <div class="w-100 qrw-label-md">{{ displayCell(cell) }}</div>
        <div
          class="event-pin"
          v-if="cell.isEvent || cell.isToday"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    value: [Date, Number, String],
    size: {
      type: String,
      default: 'md'
    },
    ranged: {
      type: Boolean,
      default: false
    },
    unit: {
      type: String,
      default: 'day',
      validator: v => ['day', 'month', 'year'].includes(v)
    },
    eventDates: {
      type: Array,
      default: () => []
    },
    locale: {
      type: String,
      default: 'uk'
    },
    minDate: {
      type: [String, Date, Number],
      default: null
    },
    maxDate: {
      type: [String, Date, Number],
      default: null
    },
    fixedWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewedDate: new Date()
    }
  },
  watch: {
    locale() {
      moment.locale(this.locale)
    }
  },
  methods: {
    updateValue(arg) {
      if (this.ranged) {
        this.$emit('input', arg)
      } else {
        this.$emit('input', moment(arg).toDate())
      }
    },
    changeMonth(delta) {
      if (this.unit === 'day') {
        this.viewedDate = moment(this.viewedDate).add(delta, 'month').toDate()
      } else if (this.unit == 'month') {
        this.viewedDate = moment(this.viewedDate).add(delta, 'year').toDate()
      } else if (this.unit == 'year') {
        this.viewedDate = moment(this.viewedDate).add(7, 'year').toDate()
      }
    },
    displayCell(cell) {
      if (this.unit == 'day') return cell.day
      if (this.unit == 'month') return moment().month(cell.month).format('MMM')
      if (this.unit == 'year') return cell.year
    },
    onCellClicked(cell) {
      const oldValue = this.ranged ? { ...this.value } : this.value

      if (!cell.isCurrentMonth || cell.isOutOfAllowedRange) return

      if (this.ranged) {
        if (!oldValue || !oldValue.start) {
          oldValue.start = moment(cell.rawDate).startOf('day').toDate()
          this.updateValue(oldValue)
        } else if (!oldValue.end) {
          oldValue.end = moment(cell.rawDate).endOf('day').toDate()

          if (moment(oldValue.start).isAfter(oldValue.end)) {
            const tmp = oldValue.start
            oldValue.start = oldValue.end
            oldValue.end = tmp
          }

          this.updateValue(oldValue)
        } else {
          oldValue.start = moment(cell.rawDate).startOf('day').toDate()
          oldValue.end = null
          this.updateValue(oldValue)
        }
      } else {
        this.updateValue(cell.rawDate)
      }
    },
    compareDates(_a, _b) {
      if (!_a || !_b) return false
      const a = moment(_a)
      const b = moment(_b)

      return a.isSame(b, this.unit)
    }
  },
  computed: {
    currentViewMoment() {
      return moment(this.viewedDate)
    },
    formattedDatepickerTitle() {
      if (this.unit === 'month') {
        return this.currentViewMoment.format('YYYY')
      }

      const str = this.currentViewMoment.format('MMMM YYYY')
      return str.substring(0, 1).toUpperCase() + str.substring(1)
    },
    weekdayNames() {
      let startDate = moment().startOf('week').add(1, 'day')

      const names = []

      for (let i = 0; i < 7; i++) {
        names.push(startDate.format('dd'))
        startDate = startDate.add(1, 'day')
      }

      return names
    },
    momentWeekdayMapping() {
      //sunday in moment is 0, we dont need that
      return [7, 1, 2, 3, 4, 5, 6]
    },
    uiCells() {
      const cells = []

      const targetDate = this.currentViewMoment

      let startDate = moment(targetDate).startOf('month')
      let endDate = moment(targetDate).endOf('month')

      if (this.unit == 'month') {
        startDate = moment(targetDate).startOf('year')
        endDate = moment(targetDate).endOf('year')
      } else if (this.unit === 'year') {
        startDate = moment(targetDate).subtract(7, 'years').startOf('year')
        endDate = moment(targetDate).add(7, 'years').endOf('year')
      }

      const today = moment()

      if (this.unit == 'day') {
        let naturalStartWeekday = this.momentWeekdayMapping[startDate.day()]

        const naturalAlignmentDiff = Math.abs(1 - naturalStartWeekday)

        startDate = startDate.subtract(naturalAlignmentDiff, 'days')

        let naturalEndWeekday = this.momentWeekdayMapping[endDate.day()]

        const naturalAlignmentEndDiff = Math.abs(7 - naturalEndWeekday)

        endDate = endDate.add(naturalAlignmentEndDiff, 'days')
      }

      while (startDate.isBefore(endDate)) {
        const cell = {
          day: startDate.date(),
          month: startDate.month(),
          year: startDate.year(),
          isCurrentMonth: this.unit == 'day' ? startDate.month() === targetDate.month() : true,
          isOutOfAllowedRange:
            (this.minDate ? moment(this.minDate).isAfter(startDate) : false) ||
            (this.maxDate ? moment(this.maxDate).isBefore(startDate) : false),
          isSelected: this.ranged
            ? this.compareDates(startDate, (this.value || {}).start) ||
              this.compareDates(startDate, (this.value || {}).end)
            : this.compareDates(startDate, this.value),
          rawDate: startDate.toDate(),
          isToday: startDate.isSame(today, this.unit),
          isEvent: this.eventDates.some(ed => moment(ed).isSame(startDate, this.unit))
        }

        if (this.ranged) {
          const valueObject = { ...this.value }

          if (valueObject.start && valueObject.end) {
            cell.isRanged = moment(cell.rawDate).isBetween(valueObject.start, valueObject.end, '[]')
          }
        }

        cells.push(cell)

        startDate = startDate.add(1, this.unit)
      }

      return cells
    }
  },
  created() {
    if (this.value) {
      this.viewedDate = this.value
    }

    moment.locale(this.locale)
  }
}
</script>

<style scoped>
.base-datepicker {
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 12px;

  background: var(--qrw-background-base-primary);
}

.datepicker-title {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  column-gap: 8px;
  text-align: center;
}

.datepicker-weekdays {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  box-sizing: border-box;
  flex-wrap: nowrap;
}

.datepicker-current-date {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
}

.weekday-name {
  width: 100%;
  text-align: center;

  display: flex;

  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.datepicker-cell {
  padding: 10px 6px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;

  text-align: center;

  background: transparent;
  color: var(--qrw-content-base-secondary);

  flex-direction: column;
  align-items: center;
  align-content: center;
  row-gap: 2px;
}

.datepicker-cell.disabled {
  opacity: 0.52;
}

.datepicker-cell.ranged {
  background: var(--qrw-background-base-secondary);
  color: var(--qrw-content-base-primary);
}

.datepicker-cell.selected {
  background: var(--qrw-background-base-inverse-secondary);
  color: var(--qrw-content-base-inverse-primary);
  box-shadow: var(--qrw-shadow-card-light);
}

.grid-cell-day {
  flex: 1 1 calc(100% / 7 - 8px);
}

.grid-cell-month {
  flex: 1 1 calc(100% / 3 - 8px);
}

.grid-cell-year {
  flex: 1 1 calc(100% / 3 - 8px);
}

.datepicker-cell.today {
}

.datepicker-calendar {
  display: flex;

  row-gap: 8px;
  column-gap: 8px;
  flex-wrap: wrap;
}

.event-pin {
  background: var(--qrw-background-error-primary);
  border-radius: 2px;
  min-width: 8px;
  max-width: 8px;
  height: 2px;
  min-height: 2px;
  display: flex;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
}

.fixed-width {
  width: 360px;
}
</style>