<template>
  <div
    class="base-divider"
    :style="{
      ...backgroundStyle,
      ...sizingStyles,
    }"
    v-if="!dashed"
  ></div>

  <div
    class="base-dashed-divider"
    ref="slicesWrapper"
    :class="[
      vertical ? 'vertical' : 'horizontal'
    ]"
    v-else
  >
    <div
      class="divider-slice"
      :style="{
        ...backgroundStyle,
        ...dashedSizingStyles
      }"
      v-for="n in slicesCount"
      :key="n"
    ></div>
  </div>
</template>

<script>
import utils from '../utils'

export default {
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    thickness: {
      type: Number,
      default: 1,
    },
    color: String,
    dashed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slicesCount: 30
    }
  },
  methods: {
    recomputeSlicesCount() {
      const el = this.$refs.slicesWrapper;

      const slicesGap = 8;
      const desiredSliceWidth = 8;

      this.slicesCount = Math.round(el.offsetWidth / (desiredSliceWidth + slicesGap));
    }
  },
  computed: {
    dashedSizingStyles() {
      if (this.vertical) {
        return {
          'height': '100%',
          'min-width': `${this.thickness}px`,
          'width': `${this.thickness}px`
        }
      } else {
        return {
          'width': '100%',
          'min-height': `${this.thickness}px`,
          'height': `${this.thickness}px`
        }
      }
    },

    sizingStyles() {
      if (!this.vertical) {
        return {
          height: `${this.thickness}px`,
          "min-height": `${this.thickness}px`,
          width: `100%`,
        };
      } else {
        return {
          width: `${this.thickness}px`,
          "min-width": `${this.thickness}px`,
          height: `100%`,
        };
      }
    },
    backgroundStyle() {
      return {
        "background-color": `var(--${utils.getVariableForColorName(this.color)})`,
      };
    },
  },
  mounted() {
    if (this.dashed) {
      this.$nextTick(this.recomputeSlicesCount);
    }
  }
};
</script>

<style scoped>
.base-divider {
  border-radius: 3px;
}

.base-dashed-divider {
  display: flex;
  overflow: hidden;
}

.base-dashed-divider.horizontal {
  flex-direction: row;
  column-gap: 8px;

  width: 100%;
}

.base-dashed-divider.vertical {
  flex-direction: column;
  row-gap: 8px;

  height: 100%;
}

.base-dashed-divider .divider-slice {
  border-radius: 8px;
}
</style>
