<template>
  <div class="qrw-drum-timepicker">
    <div
      class="scroll-column"
      ref="c0"
    >
      <div class="selected-block"></div>

      <div class="scroll-contents">
        <div
          v-for="(item,key) in availableDates"
          class="scroll-item qrw-label-md"
          @click="selectDate(key)"
          :ref="'c0_'+key"
          :key="key"
          :class="{
            'active bold': item === availableDates[selection[0]]
          }"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div
      class="scroll-column"
      ref="c1"
    >
      <div class="selected-block"></div>

      <div class="scroll-contents">
        <div
          v-for="(item,key) in availableTimes"
          @click="selectTime(key)"
          class="scroll-item qrw-label-md"
          :ref="'c1_'+key"
          :key="key"
          :class="{
            'active bold': key === selection[1]
          }"
        >
          {{ formatTime(item) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// const PICKER_HEIGHT = 240
const SCROLL_ITEM_HEIGHT = 40

import moment from 'moment'

function executeRelativeSmoothScroll(element, to, duration, property, callback) {
  const start = element[property || 'scrollLeft']
  const change = to - start
  let currentTime = 0
  const increment = 20

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  const animateScroll = () => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    element[property || 'scrollLeft'] = val
    if (currentTime <= duration) {
      setTimeout(animateScroll, increment)
    } else {
      element[property || 'scrollLeft'] = to
      if (callback) callback()
    }
  }
  animateScroll()
}

export default {
  props: {
    value: [String, Number, Date],
    choices: Object
  },
  data() {
    return {
      selectedDateKey: 0,
      selectedDatetime: null,

      selection: [0, 0],

      lastDirection: 0,
      lastTouchPos: 0,
      isMoving: false
    }
  },
  methods: {
    formatTime(date) {
      return moment(date).format('HH:mm')
    },
    // TODO: please refactor select date/time to not have duplicated code from drumify()
    selectDate(selected) {
      this.$set(this.selection, 0, selected)
      this.$set(this.selection, 1, 0)

      const newScrollPos = this.selection[0] * SCROLL_ITEM_HEIGHT

      executeRelativeSmoothScroll(this.$refs['c0'], newScrollPos, 100, 'scrollTop', () => {
        this.$refs['c1'].scrollTop = 0
        this.$emit('input', this.availableTimes[this.selection[1]])
      })
    },
    selectTime(selected) {
      this.$set(this.selection, 1, selected)

      const newScrollPos = this.selection[1] * SCROLL_ITEM_HEIGHT

      executeRelativeSmoothScroll(this.$refs['c1'], newScrollPos, 100, 'scrollTop', () => {
        this.$emit('input', this.availableTimes[this.selection[1]])
      })
    },
    drumify(el, index) {
      // const itemsCount = items.length
      let timeout = null

      const emitValue = () => {
        this.$emit('input', this.availableTimes[this.selection[1]])
      }

      const updateSelection = () => {
        const scrollPos = el.scrollTop
        const selected = Math.round(scrollPos / SCROLL_ITEM_HEIGHT)

        this.$set(this.selection, index, selected)

        if (index === 0) {
          if (this.availableTimes.length <= this.selection[1]) {
            this.selection[1] = 0
            this.$refs['c1'].scrollTop = 0
          }
        }

        emitValue()
      }

      const attachToNearestItem = () => {
        let remainder = el.scrollTop % SCROLL_ITEM_HEIGHT
        let fixedScrollPos =
          remainder > SCROLL_ITEM_HEIGHT / 2
            ? Math.round(el.scrollTop + SCROLL_ITEM_HEIGHT - remainder)
            : Math.round(el.scrollTop - remainder)

        // if (this.lastDirection > 0) {
        //   fixedScrollPos -= remainder
        // } else if (this.lastDirection < 0) {
        //   fixedScrollPos += SCROLL_ITEM_HEIGHT - remainder
        // }

        executeRelativeSmoothScroll(el, fixedScrollPos, 100, 'scrollTop', () => {
          updateSelection()
        })
      }

      el.addEventListener('scroll', () => {
        if (!this.isMoving) {
          clearTimeout(timeout)
          timeout = setTimeout(attachToNearestItem, 100)
        }
      })

      el.addEventListener('touchstart', ev => {
        this.lastTouchPos = ev.touches[0].clientY
        this.isMoving = true
      })

      el.addEventListener('touchmove', ev => {
        const touch = ev.touches[0]
        const touchY = touch.clientY

        this.lastDirection = touchY - this.lastTouchPos
        this.lastTouchPos = touchY

        updateSelection()
      })

      el.addEventListener('touchend', () => {
        attachToNearestItem()
        this.lastDirection = 0
        this.isMoving = false
      })

      el.scrollTop = this.selection[index] * SCROLL_ITEM_HEIGHT
    }
  },
  computed: {
    availableDates() {
      return Object.keys(this.choices)
    },
    availableTimes() {
      return this.choices[this.availableDates[this.selection[0]]]
    }
  },
  mounted() {
    this.selection[0] = 0

    if (this.value) {
      for (const [key, dates] of Object.entries(this.choices)) {
        const matchingDate = dates.find(d => moment(d).isSame(this.value, 'minute'))

        if (matchingDate) {
          this.selection = [this.availableDates.indexOf(key), dates.indexOf(matchingDate)]
          break
        }
      }
    }

    this.drumify(this.$refs.c0, 0)
    this.drumify(this.$refs.c1, 1)
  }
}
</script>

<style scoped>
.qrw-drum-timepicker {
  width: 100%;
  display: flex;
  background: var(--qrw-background-base-inverse-primary-01);

  height: 240px;
  min-height: 240px;
  max-height: 240px;

  width: 100%;

  position: relative;

  overflow: hidden;
}

.scroll-column {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: scroll;
}

.selected-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  background: var(--qrw-background-base-primary-10);
  width: 100%;

  position: fixed;
}

.scroll-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  box-sizing: border-box;
  text-align: center;
  color: var(--qrw-content-base-tertiary);
  cursor: pointer;
}

.scroll-item.active {
  color: var(--qrw-content-base-primary);
}

.scroll-contents {
  position: absolute;

  overflow: scroll;
  top: calc(240px / 2 - 20px);
  padding-bottom: calc(240px / 2 - 20px);

  z-index: 1;
}
</style>