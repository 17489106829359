<template>
  <input
    class="qrw-input-text ui-phone-input"
    pattern="[0-9\-]*"
    autocomplete="tel"
    @input="onPhoneEntered"
    type="text"
    ref="inputEl"
  />
</template>

<style>
.iti,
.iti input {
  width: 100%;
  padding: 0;
  line-height: 16px;
  color: var(--grey-10-color);
}

.iti__selected-dial-code {
  font-size: 14px;
}

.iti__selected-flag {
  background: var(--grey-4-color) !important;
}

.iti,
.iti input {
  outline: none !important;
}

.iti__flag-container {
  padding: 0;
  border-radius: 4px 0 0 4px;
}

.iti:focus,
.iti:focus-within,
.iti input:focus {
  /* border: none !important; */
}
</style>

<style scoped>
.phone-input-wrapper {
  background: transparent;
  display: flex;
}

.ui-phone-input {
  padding: 7px;
  border: 1px solid var(--grey-5-color);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none !important;
}

.ui-phone-input::placeholder {
  color: var(--grey-7-color) !important;
}

.phone-select {
  background: var(--white-color);
  border: none;
  width: 64px;
  margin-right: 8px;
}
</style>

<script>
import intlTelInput from "intl-tel-input";

let itiInstance = null;

export default {
  data() {
    return {
      itiInstance: null,
    };
  },
  methods: {
    init() {
      itiInstance = intlTelInput(this.$refs.inputEl, {
        initialCountry: "ua",
        separateDialCode: true,
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
      });
    },
    onPhoneEntered() {
      if (!itiInstance.isValidNumber()) {
        return;
      }

      this.$emit("input", itiInstance.getNumber());
    },
  },
  mounted() {
    this.$nextTick(this.init);
  },
  beforeDestroy() {
    if (this.itiInstance) this.itiInstance.destroy();
  },
};
</script>