<template>
  <div class="base-tab-button" :class="{
    [`qrw-label-${textSize}`]: true,
    'active bold': active,
    [size]: true
  }" @click="$emit('click')">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "md",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textSize() {
        return {
            'md': 'lg',
            'sm': 'md',
            'xs': 'sm',
        }[this.size];
    },
  },
};
</script>

<style scoped>
@keyframes active {
  0% {
    color: var(--qrw-content-base-secondary);
    opacity: 0.7;
  }
  50% {
    color: var(--qrw-content-base-inverse-primary);
  }
  100% {
    background: var(--qrw-background-base-inverse-secondary);
    color: var(--qrw-content-base-inverse-primary);
    opacity: 1;
  }
}

.base-tab-button {
  white-space: nowrap;
  color: var(--qrw-content-base-secondary);
  transition: 0.35s all;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  width: fit-content;
}

.base-tab-button.active {
  background: var(--qrw-background-base-inverse-secondary-10);
  color: var(--qrw-content-base-inverse-primary);
  box-shadow: var(--qrw-shadow-foundation-1dp);
  animation: active 0.35s linear;
}

.base-tab-button.md {
  padding: 8px 16px;
  transition: 0.35s all;
  border-radius: 10px;
}

.base-tab-button.sm {
  padding: 6px 12px;
  transition: 0.35s all;
  border-radius: 8px;
}

.base-tab-button.xs {
  padding: 4px 8px;
  transition: 0.35s all;
  border-radius: 6px;
}
</style>