<template>
  <div
    :class="{
      'qrw-select-button': true,
      [size]: true,
      selected: isSelected,
      [`qrw-label-${size}`]: true,
      bold: true,
    }"
    @click="onClick"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, String, Number, Object],
    },
    selectedValue: {
      type: [Boolean, String, Number, Object],
      default: true,
    },
    size: {
      type: String,
      default: "sm",
      validator: (v) => ["xs", "sm", "md", "lg"].includes(v),
    },
  },
  methods: {
    onClick() {
      this.$emit("input", this.selectedValue);
    },
  },
  computed: {
    isSelected() {
      return this.value === this.selectedValue;
    },
  },
};
</script>

<style scoped>
.qrw-select-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid var(--qrw-border-base-secondary);
  color: var(--qrw-content-base-secondary);
  cursor: pointer;
}

.xs {
  padding: 4px 10px;
  border-radius: 6px;
}

.sm {
  padding: 7px 16px;
  border-radius: 8px;
}

.md {
  padding: 9px 20px;
  border-radius: 10px;
}

.lg {
  padding: 11px 24px;
  border-radius: 12px;
}

.selected {
  background: var(--qrw-background-base-inverse-secondary);
  color: var(--qrw-on-content-primary);
  border: 0;
  box-shadow: var(--qrw-shadow-foundation-1dp);
}

.xs.selected {
  padding: 5px 10px;
}

.sm.selected {
  padding: 8px 16px;
}

.md.selected {
  padding: 10px 20px;
}

.lg.selected {
  padding: 12px 24px;
}
</style>