<template>
  <div class="language-switcher">
    <div v-for="(lang, index) in languageList" :key="index">
      <div
        class="available-lang"
        :class="{ filled: lang.isFilled, selected: value === lang.name }"
        @click="$emit('input', lang.name)"
      >
        <img
          :src="`https://cdn.qrwaiter.com.ua/assets/flags/${lang.name}.${extension}`"
        />
      </div>
    </div>
    <BaseDivider vertical />
    <img
      :src="`https://cdn.qrwaiter.com.ua/assets/flags/${defaultLang}.${extension}`"
      @click="$emit('input', defaultLang)"
      class="default-lang"
      :class="{ selected: value === defaultLang }"
    />
  </div>
</template>

<style scoped>
.language-switcher {
  display: flex;
  align-items: baseline;
}

.available-lang {
  display: block;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--red-4-color);
  border-radius: 0.5px;
  margin-right: 8px;
}

.available-lang img {
  opacity: 0.48;
  cursor: pointer;

  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.available-lang.filled {
  border-bottom: 1px solid var(--green-7-color);
}

.available-lang.selected img,
img.default-lang.selected {
  cursor: pointer;
  opacity: 1;
}

img.default-lang {
  opacity: 0.48;
  cursor: pointer;
  padding-left: 8px;

  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
}
</style>

<script>
export default {
  props: {
    languageList: [],
    value: String,
    defaultLang: String,
    extension: {
      type: String,
      default: "png",
    },
  },
};
</script>