<template>
  <div class="smart-scroller-wrapper" ref="wrapper" v-if="canShow">
      <div  
        @mousedown="onControlMouseDown"
        @mouseup="onControlMouseUp"
        @mouseleave="onControlMouseLeave"
        class="smart-scroller-control" 
        :class="{'focus':grabbing}"
        ref="control"></div>
  </div>
</template>

<script>
export default {
    props: {
        selector: String,
        element: Object,
        speed: {
            type: Number,
            default: 2
        }
    },

    data() {
        return {
            el: null,
            canShow: false,

            grabbing: false
        }
    },

    watch: {
        'canShow'() {
            this.$emit('checked', this.canShow);
        }
    },

    methods: {
        fetchElement() {
            this.el = this.element || document.querySelector(this.selector) || null;

            if (this.el) {
                this.check();
                this.el.addEventListener('scroll', this.onTargetScrolled);

                if (this.canShow) {
                    this.$nextTick(this.onTargetScrolled);
                }
            }
        },
        check() {
            this.canShow = this.el.scrollWidth > this.el.getBoundingClientRect().width;
        },
        onControlMouseDown() {
            this.grabbing = true;
        },
        onControlMouseUp() {
            this.grabbing = false;
        },
        onTargetScrolled() {
            this.check();

            if (!this.canShow) return;

            const controlEl = this.$refs.control;

            const currentControlWidth = controlEl.getBoundingClientRect().width;

            const ratio = this.el.scrollLeft / (this.el.scrollWidth - this.el.getBoundingClientRect().width);

            const fullScrollerWidth = this.$refs.wrapper.getBoundingClientRect().width;

            const newPosition = Math.round(ratio * (fullScrollerWidth - currentControlWidth));

            controlEl.style.left = `${newPosition}px`;

            const newControlWidth = (this.el.getBoundingClientRect().width / this.el.scrollWidth) * fullScrollerWidth

            controlEl.style.width = `${newControlWidth}px`;
        },
        onControlMouseLeave() {

        },
        onControlMouseMove(ev) {
            if (!this.grabbing || !this.canShow) return;

            const xDelta = ev.movementX;

            if (!xDelta) return;

            const xMovement = xDelta * this.speed;

            if (xDelta > 0) {
                this.el.scrollLeft = Math.min(
                    this.el.scrollWidth,
                    this.el.scrollLeft + xMovement
                );
            } else {
                this.el.scrollLeft = Math.max(
                    0,
                    this.el.scrollLeft + xMovement
                );
            }

            this.onTargetScrolled();
            ev.preventDefault();
        }
    },
    mounted() {
        this.$nextTick(this.fetchElement);

        window.addEventListener('mouseup', this.onControlMouseUp);
        window.addEventListener('mousemove', this.onControlMouseMove);
    },
    beforeDestroy() {
        window.removeEventListener('mouseup', this.onControlMouseUp);
        window.removeEventListener('mousemove', this.onControlMouseMove);
        if (this.el) this.el.removeEventListener('scroll', this.onTargetScrolled)
    }
}
</script>

<style scoped>
.smart-scroller-wrapper {
    border-radius: 2px;
    min-height: 8px;
    width: 100%;
    background: var(--v1-grey-3-color);
    user-select: none;
}

.smart-scroller-control {
    background: var(--v1-grey-5-color);
    min-height: 8px;
    cursor: pointer;
    transition:0.3s;
    border-radius: 2px;

    position: relative;

    user-select: none;
}

.smart-scroller-control:hover {
    background: var(--v1-grey-10-color);
}

.smart-scroller-control.focus {
    transform: scaleY(1.2);
    background: var(--v1-grey-10-color);
}
</style>