<template>
  <div class="text-divider">
    <BaseDivider :color="color" />
    <h4 :class="{
      [`qrw-label-${textSize}`]: true
    }" :style="textStyles">{{ info }}</h4>
    <BaseDivider :color="color" />
  </div>
</template>

<script>
import Utils from '@/utils.js'

export default {
  props: {
    info: String,
    color: {
      type: String,
      default: 'border-base-tertiary'
    },
    textColor: {
      type: String,
      default: 'content-base-tertiary'
    },
    textSize: {
      type: String,
      default: 'sm'
    } 
  },
  computed: {
    textStyles() {
      return {
        'color': `var(--${Utils.getVariableForColorName(this.textColor || this.color)})`
      }
    }
  }
};
</script>

<style scoped>
.text-divider {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  column-gap: 24px;
}

h4.text-divider {
  display: flex;
  justify-content: center;
  min-width: fit-content;
}
</style>