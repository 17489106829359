<template>
  <div class="unit-input-wrapper" :class="{'disabled':disabled}">
    <BaseSelect :disabled="disabled" :valid="valid" @input="onSelect" :value="value">
      <slot/>
    </BaseSelect>
    <BaseInput 
      class="unit-box" 
      hasUnit 
      disabled 
      :value="unitPlaceholder" 
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    unitPlaceholder: String,
    placeholder: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [String, Number, Object],
    valid: {
      type:Boolean,
      default:true
    }
  },
  methods: {
     onSelect(ev) {
      this.$emit("input", ev);
    },
  }
};
</script>


<style scoped>

.unit-input-wrapper {
    background: var(--v1-grey-4-color);
    width: fit-content;
    border-radius: 4px;
    display: flex;
}

.unit-input-wrapper.disabled {
    background: var(--v1-grey-3-color);
}

.unit-box {
    width: fit-content;
    padding: 0;
    align-items: center;
}

.unit-input::placeholder {
    color:var(--v1-grey-7-color);
}
</style>