<template>
  <input
    type="range"
    :min="min"
    :max="max"
    :value="value"
    @input="handleInput"
    @change="handleChange"
    :class="rangeClasses"
  />
</template>

<script>
export default {
  props: {
    value: Number,
    min: {
      type: Number,
      default: 0,
    },
    lineColor: {
      type: String,
      default: "",
    },
    pointerColor: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", +e.target.value);
    },
    handleChange(e) {
      this.$emit("change", +e.target.value);
    },
  },
  computed: {
    rangeClasses() {
      return {
        grey: this.lineColor == "grey",
        white: this.pointerColor == "white",
      };
    },
  },
};
</script>

<style scoped>
input[type="range"] {
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  background: transparent;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: var(--v1-grey-4-color);
  border-radius: 2px;
}

input[type="range"].grey::-webkit-slider-runnable-track {
  background: var(--v1-grey-8-color);
}

input[type="range"]::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  background: var(--green-7-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
  transition: 0.1s;
}

input[type="range"]::-webkit-slider-thumb:hover,
input[type="range"]::-webkit-slider-thumb:active {
  cursor: grabbing;
  width: 40px;
  height: 40px;
  margin-top: -16px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8;
  cursor: pointer;
  border-radius: 2px;
}
input[type="range"]::-moz-range-thumb {
  height: 24px;
  width: 24px;
  border-radius: 3px;
  background: var(--green-7-color);
  cursor: pointer;
}

input[type="range"].white::-webkit-slider-thumb {
  background: var(--v1-grey-2-color);
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  border-radius: 2.6px;
}
input[type="range"]::-ms-fill-upper {
  border-radius: 2.6px;
}
input[type="range"]::-ms-thumb {
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: var(--v1-grey-4-color);
  cursor: pointer;
}
</style>