<template>
  <div
    class="base-number-input-wrapper"
    :class="[size]"
  >
    <div
      class="number-unit-box qrw-text-content-base-tertiary"
      :class="`qrw-label-${size} ${size}`"
      v-if="unit && unitPosition == 'left'"
    >
      {{ unit }}
    </div>

    <div
      class="qrw-common-input-container qrw-base-number-input-container"
      :class="{
        [`size-${size}`]: true,
        
      }"
    >
      <input
        :type="type"
        :value="value"
        :disabled="disabled"
        inputmode="numeric"
        @keypress="isNumber($event)"
        @focus="
          isFocused = true;
          $emit('focus');
        "
        @blur="
          isFocused = false;
          $emit('blur');
        "
        @input="onInput"
        class="qrw-common-input-element qrw-base-number-input-element"
        :class="{
          [`qrw-paragraph-${size}`]: true,
          'state-error': invalid,
          [`size-${size}`]: true,
          'state-disabled': disabled,
          'state-focus': isFocused,
        }"
        :placeholder="placeholder"
      />

      <div
        class="number-controls"
        :class="{ [size]: true }"
        v-if="!hideControls && !disabled"
      >
        <BaseIcon
          icon="chevron-up"
          @click="applyStep(1)"
          :size="null"
        />
        <BaseIcon
          icon="chevron-down"
          @click="applyStep(-1)"
          :size="null"
        />
      </div>
    </div>

    <div
      class="number-unit-box qrw-text-content-base-tertiary"
      :class="`qrw-label-${size} ${size}`"
      v-if="unit && unitPosition == 'right'"
    >
      {{ unit }}
    </div>
  </div>
</template>

<script>
import './inputCommons.scss'

export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '0'
    },
    type: {
      type: String,
      default: 'number'
    },
    size: {
      type: String,
      default: 'sm',
      validator: v => ['sm', 'md', 'lg'].includes(v)
    },
    invalid: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    },
    forceFocus: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    unit: {
      type: String,
      default: null
    },
    unitPosition: {
      type: String,
      default: 'right'
    }
  },
  watch: {
    forceFocus() {
      this.isFocused = this.forceFocus
    }
  },
  data() {
    return {
      isFocused: false
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },
    isNumber(evt) {
      this.$emit('keypress', evt)

      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
        return
      }

      if (this.max == null) return

      const digit = +evt.key

      const futureValueString = evt.target.value.startsWith('0') ? digit.toString() : `${evt.target.value}${digit}`
      const futureValue = +futureValueString

      console.log(futureValue, futureValueString)

      if (this.max !== null && (futureValue > this.max || futureValueString.length > this.max.toString().length))
        evt.preventDefault()
      if (this.min !== null && futureValue < this.min) evt.preventDefault()
    },
    applyStep(delta) {
      let oldValue = this.value || 0
      oldValue += delta
      this.$emit('input', oldValue)
    }
  }
}
</script>

<style scoped>
.qrw-base-number-input-container.size-sm {
  column-gap: 6px;
}

.qrw-base-number-input-container.size-md {
  column-gap: 8px;
}

.qrw-base-number-input-container.size-lg {
  column-gap: 10px;
}

.number-controls {
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  align-items: center;
  align-self: center;
  justify-content: center;
  user-select: none;

  position: absolute;
}

.qrw-base-number-input-container.size-lg .number-controls {
}

.number-controls span {
  cursor: pointer;
}

/* .number-controls.sm {
  height: 20px;
}

.number-controls.md {
  height: 24px;
}

.number-controls.lg {
  height: 28px;
} */

.number-controls.sm {
  font-size: 12px;

  right: 6px;
  top: 4px;
}

.number-controls.md {
  font-size: 14px;

  right: 8px;
  top: 6px;
}

.number-controls.lg {
  font-size: 16px;

  right: 10px;
  top: 8px;
}

.qrw-base-number-input-element::-webkit-outer-spin-button,
.qrw-base-number-input-element::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.qrw-base-number-input-element {
  -moz-appearance: textfield;
}

.number-unit-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;
}

.number-unit-box.lg {
  padding: 12px 16px;
}

.number-unit-box.md {
  padding: 10px 14px;
}

.number-unit-box.sm {
  padding: 8px 12px;
}

.base-number-input-wrapper {
  overflow: hidden;
  background: var(--qrw-background-base-tertiary);
  display: flex;
}

.base-number-input-wrapper.sm {
  border-radius: 8px;
}

.base-number-input-wrapper.md {
  border-radius: 10px;
}

.base-number-input-wrapper.lg {
  border-radius: 12px;
}

.qrw-base-number-input-container.state-disabled {
  border: 1px solid var(--qrw-border-base-tertiary);
}
</style>