<template>
  <div class="simple-modal-wrapper">
    <div
      class="simple-modal-backdrop"
      @click="$emit('backdropClick')"
    ></div>

    <div
      class="qrw-simple-modal qrw-simple-modal-adaptive"
      :class="{
      'full-height': fullHeight,
      'fit-content':fitContent,
      'full-width': fullWidth,
      [modalClass]: !!modalClass
    }"
    >
      <BaseSimpleModalHeader
        :title="title"
        :subtitle="subtitle"
        :showClose="showClose"
        @closeClicked="$emit('close')"
        v-if="showHeader"
      />

      <div
        class="modal-body"
        :class="bodyClass"
      >
        <slot />
      </div>

      <div
        class="modal-footer"
        v-if="showFooter && !useCustomFooter"
      >
        <BaseDivider color="border-base-tertiary" />
        <div class="modal-footer-content">
          <slot name="footer" />
        </div>

      </div>
      <slot
        name="footer"
        v-else-if="showFooter && useCustomFooter"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    bodyClass: [String, Array, Object],
    modalClass: String,
    showHeader: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    fullHeight: {
      type: Boolean,
      default: true
    },
    fitContent: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    useCustomFooter: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.document.body.style.overflow = 'hidden'
  },
  beforeDestroy() {
    window.document.body.style.overflow = 'auto'
  }
}
</script>

<style lang="scss" scoped>
.qrw-simple-modal {
  border-radius: 16px 16px 0 0;
  background: var(--qrw-background-base-secondary);
  overflow: hidden;
  box-shadow: var(--qrw-shadow-modal);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.modal-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
}

.modal-footer-content {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
}

.simple-modal-backdrop {
  position: fixed;
  z-index: 1990;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--qrw-background-base-inverse-primary-08);
  backdrop-filter: blur(2px);
  cursor: pointer;
}

.simple-modal-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

/* mobile */
.qrw-simple-modal-adaptive {
  position: fixed;
  z-index: 2000;
  width: 100%;
  bottom: 0;
  left: 0;
}

/* tablet/desktop */
@include adapt-from('mobile-large') {
  .modal-header {
    padding: 16px 24px;
  }
}

@include adapt-from('tablet-small') {
  .qrw-simple-modal-adaptive {
    position: fixed;
    z-index: 2000;
    width: 522px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 16px;
  }

  .modal-footer-content {
    padding: 16px 24px;
    column-gap: 16px;
    flex-direction: row;
  }
}

@include adapt-from('tablet-medium') {
  .qrw-simple-modal-adaptive {
    width: 690px;
  }
}

@include adapt-from('tablet-large') {
  .qrw-simple-modal-adaptive {
    width: 880px;
  }
}

.qrw-simple-modal.full-height {
  height: 80vh;
  max-height: 80vh;
}

.qrw-simple-modal.fit-content {
  height: fit-content;
  max-height: 80vh;
}

.qrw-simple-modal.full-width {
  width: 90vw;
  max-width: 90vw;
}
</style>