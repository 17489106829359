<template>
  <div class="checkbox-toggle" @click="emitValue">
    <div
      class="checkbox-item"
      :class="{ disabled: readonly || disabled }"
      :style="
        !!value
          ? `background-color:${bgColor}; border:1px solid transparent`
          : ''
      "
      v-if="!checker"
    ></div>
    <BaseIcon :class="{
      disabled: readonly || disabled
    }" :icon="toggleImg" v-if="checker" />
    <h3
      class="qrw-heading-sm circle-label"
      :class="{
        [`${defaultTextColor}-color`]: !value,
        [`${checkedTextColor}-color`]: !!value,
      }"
      v-if="hasContent"
    >
      <slot></slot>
    </h3>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checker: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "var(--green-6-color)",
    },
    checkedTextColor: {
      type: String,
      default: "grey-10",
    },
    defaultTextColor: {
      type: String,
      default: "grey-7",
    }
  },
  methods: {
    emitValue() {
      if (!this.readonly) this.$emit("input", !this.value);
    },
  },
  computed: {
    toggleImg() {
      return this.value ? "square-checker-on" : "square-checker-off";
    },
    hasContent() {
      return !!this.$slots.default;
    },
  },
};
</script>

<style scoped>
.checkbox-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-item {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  border: 1px solid var(--v1-grey-6-color);
  border-radius: 2px;
}

.checkbox-item.disabled {
  cursor: not-allowed;
  opacity: 0.52;
}

.circle-label {
  margin-left: 8px;
}
</style>
