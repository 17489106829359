import utils from "../../utils";

export default ({
  bgColor,
  textColor,
  borderColor,
  hoverColor,
  textHoverColor,
  borderHoverColor,
  hovered,
  variant,
}) => {
  if (variant == "primary" || variant == "fill") {
    const vBgColor = hovered
      ? hoverColor || utils.getColorTokenSibling(bgColor, 1)
      : bgColor;

    const vTextColor =
      textColor ||
      utils.makeColorTokenWithLevel(
        "content",
        utils.extractColorTokenLevel(bgColor)
      );

    const bgColorVar = utils.getVariableForColorName(vBgColor);
    const textColorVar = utils.getVariableForColorName(vTextColor);

    return {
      "background-color": `var(--${bgColorVar})`,
      color: `var(--${textColorVar})`,
    };
  }

  if (variant == "secondary" || variant == "stroke") {
    const vHoveringBgColor =
      bgColor ||
      utils.makeColorTokenWithLevel(
        "content",
        utils.extractColorTokenLevel(textColor)
      );

    const vTextColor = hovered
      ? hoverColor || utils.getOppositeColor(textColor)
      : textColor;

    const vBorderColor = hovered ? vHoveringBgColor : borderColor || vTextColor;

    const hoveringBgColorVar = utils.getVariableForColorName(vHoveringBgColor);
    const textColorVar = utils.getVariableForColorName(vTextColor);
    const borderColorVar = utils.getVariableForColorName(vBorderColor);

    return {
      "background-color": hovered
        ? `var(--${hoveringBgColorVar})`
        : "transparent",
      border: `1px solid var(--${borderColorVar})`,
      color: `var(--${textColorVar})`,
    };
  }

  if (variant == "tertiary" || variant == "ghost") {
    const vTextColor = textColor;
    const vHoverTextColor = textHoverColor || textColor;
    const vHoveringBgColor =
      hoverColor || "overlay-8";

    const hoveringBgColorVar = utils.getVariableForColorName(vHoveringBgColor);
    const textColorVar = utils.getVariableForColorName(hovered ? vHoverTextColor : vTextColor);

    return {
      "background-color": hovered
        ? `var(--${hoveringBgColorVar})`
        : "transparent",
      color: `var(--${textColorVar})`,
    };
  }

  if (variant === 'outlined') {
    const vTextColor = hovered ? textHoverColor || textColor : textColor;
    const vBorderColor = hovered ? borderHoverColor || 'border-base-primary' : borderColor || textColor;

    return {
      'background-color': 'transparent',
      'color': utils.tokenToCssVar(vTextColor),
      'border': `1px solid ${utils.tokenToCssVar(vBorderColor)}`
    }
  }

  return {};
};
