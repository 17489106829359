<template>
  <div
    class="qrw-toggle-container"
    :class="{active: value, [size]: true, disabled}"
    @click="onClick"
  >
    <div
      class="qrw-toggle-control"
      :class="{active: value, [size]: true}"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'xs'
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    }
  }
}
</script>

<style scoped>
/* Toggle Container */
.qrw-toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  box-sizing: border-box;

  border: 1px solid var(--qrw-border-base-tertiary);
  background: var(--qrw-background-base-tertiary-10);

  transition: 0.2s;

  user-select: none;

  cursor: pointer;
}

.qrw-toggle-container.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.qrw-toggle-container.xs {
  border-radius: 24px;
  width: 48px;
  height: 28px;
  padding: 2px;
  min-width: 48px;
}

.qrw-toggle-container.sm {
  border-radius: 32px;
  width: 56px;
  height: 32px;
  padding: 2px;
  min-width: 56px;
}

.qrw-toggle-container.md {
  border-radius: 32px;
  width: 70px;
  height: 40px;
  padding: 2px;
  min-width: 70px;
}

.qrw-toggle-container.lg {
  border-radius: 32px;
  width: 84px;
  height: 48px;
  padding: 2px;
  min-width: 84px;
}

.qrw-toggle-container.active {
  background: var(--qrw-background-success-default);
  border: 1px solid var(--qrw-border-success-primary);
  justify-content: flex-end;

  transition: 0.2s;
}

/* Toggle Control */
.qrw-toggle-control {
  background: var(--qrw-background-base-primary);
}

.qrw-toggle-control.xs {
  width: 22px;
  height: 22px;
  max-width: 22px;
  min-height: 22px;
  border-radius: 27.5px;
}

.qrw-toggle-control.sm {
  width: 26px;
  height: 26px;
  max-width: 26px;
  min-height: 26px;
  border-radius: 27.5px;
}

.qrw-toggle-control.md {
  width: 34px;
  height: 34px;
  max-width: 34px;
  min-height: 34px;
  border-radius: 27.5px;
}

.qrw-toggle-control.lg {
  width: 42px;
  height: 42px;
  max-width: 42px;
  min-height: 42px;
  border-radius: 27.5px;
}

.qrw-toggle-control.active {
  box-shadow: var(--qrw-shadow-foundation-1dp);
}
</style>