<template>
  <button
    @click="onClick"
    :class="btnClass"
    :style="btnStyle"
    :disabled="disabled"
    @mouseenter="onMouseEvent('enter')"
    @mouseleave="onMouseEvent('leave')"
    @touchend="onMouseEvent('touchend')"
    ref="buttonEl"
  >
    <!-- left icon -->
    <BaseIcon
      :size="btnIconSize"
      :icon="icon"
      :class="leftIconClass"
      v-if="!busy && icon && (iconPosition == 'left' || iconPosition == 'both')"
    ></BaseIcon>

    <BaseLoaderIcon
      class="loading-icon"
      :size="null"
      v-if="busy"
    />

    <div class="button-content-wrap" v-show="!busy"><slot /></div>

    <!-- right icon -->
    <BaseIcon
      :icon="icon"
      :size="btnIconSize"
      :class="rightIconClass"
      :balloon="iconBalloon"
      v-if="
        !busy && icon && (iconPosition == 'right' || iconPosition == 'both')
      "
    ></BaseIcon>
  </button>
</template>

<script>
import Utils from "../../utils";

export default {
  props: {
    variant: String,
    size: {
      type: String,
      default: "xs",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    icon: String,
    busy: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "grey-9",
    },
    iconBalloon: {
      type: [String, Boolean],
      default: false
    },
    iconPosition: {
      type: String,
      default: "left",
    },
    align: {
      type: String,
      default: "center",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    contentColor: {
      type: String,
      default: null
    },
    overrideIconOnly: {
      type: Boolean,
      default: false
    },
    resetHoverOnClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      last_nonbusy_width: 0,
      last_nonbusy_height: 0,

      is_hovered: false,
    };
  },
  methods: {
    onClick() {
      if (!this.busy) this.$emit("click");

      if (this.isMobile || this.resetHoverOnClick) this.is_hovered = false;
    },
    onMouseEvent(type) {
      this.is_hovered = type == 'enter';
    }
  },
  watch: {
    busy(newVal) {
      this.last_nonbusy_width = newVal
        ? this.$refs.buttonEl.getBoundingClientRect().width
        : 0;

      this.last_nonbusy_height = newVal
        ? this.$refs.buttonEl.getBoundingClientRect().height
        : 0;
    },
  },
  computed: {
    btnClass() {
      // const typographySizeMappings = {
      //   sm: 'xs',
      //   md: 'sm',
      //   lg: 'md'
      // }

      return {
        [`qrw-button-text-${this.size}`]: true,
        [this.size]: true,
        [this.variant]: true,
        "with-icon": !!this.icon,
        "icon-only": this.icon && !this.hasButtonContent,
        fullwidth: this.fullWidth,
        loader: this.busy,
        bold: this.variant == "primary" || this.bold,
      };
    },
    btnStyle() {
      const borderWidth = this.variant == "secondary" ? 1 : 0;
      const iconPaddings = {
        sm: 10,
        md: 16,
        lg: 22,
      };

      const iconOnlyPaddings = {
        sm: 4,
        md: 7,
        lg: 10,
      };

      let fillColor = this.color;
      let textColor = this.color;

      if (this.is_hovered && !this.busy && this.variant !== 'tertiary') {
        fillColor = Utils.getColorSibling(fillColor, +1);
        textColor = Utils.getMatchingColorForBackground(fillColor);
      }

      if (this.contentColor) {
        textColor = this.contentColor;
      }

      const base = {
        //only primary buttons have color
        "background-color":
          this.variant == "primary" || this.is_hovered && this.variant !== 'tertiary'
            ? `var(--${fillColor}-color)`
            : "transparent",

        color:
          this.variant == "primary"
            ? `var(--${this.contentColor || Utils.getMatchingColorForBackground(fillColor)}-color)`
            : `var(--${textColor}-color)`,

        border:
          this.variant === "secondary"
            ? `1px solid var(--${fillColor}-color)`
            : "none",
        // this.variant == "secondary"
        //   ? `1px solid var(--${this.color}-color)`
        //   : "0",

        "text-align": this.align,
        "justify-content": this.align,
      };

      const iconPadding = iconPaddings[this.size];

      if ((!this.hasButtonContent && (this.icon || this.busy)) || this.overrideIconOnly) {
        let paddingSize = iconOnlyPaddings[this.size] - borderWidth;
        base["padding"] = paddingSize + "px";
      }

      //if we have an icon, additional paddings apply based on size
      if (!this.overrideIconOnly) if (
        (this.hasButtonContent && this.icon && this.iconPosition == "left") ||
        this.iconPosition == "both"
      ) {
        base["padding-left"] = `${iconPadding - borderWidth}px`;
      }

      if (!this.overrideIconOnly) if (
        (this.hasButtonContent && this.icon && this.iconPosition == "right") ||
        this.iconPosition == "both"
      ) {
        base["padding-right"] = `${iconPadding - borderWidth}px`;
      }

      if (this.last_nonbusy_width) {
        base["width"] = `${this.last_nonbusy_width}px`;
      }
      if (this.last_nonbusy_height) {
        base["height"] = `${this.last_nonbusy_height}px`;
      }

      if (this.variant == 'tertiary' && this.is_hovered) {
        base['background-color'] = 'rgba(31, 31, 31, 0.16)';
      }

      return base;
    },
    leftIconClass() {
      return {
        "btn-icon": true,
        "btn-icon-left-sm": this.size == "sm" && this.hasButtonContent,
        "btn-icon-left-md": this.size == "md" && this.hasButtonContent,
        "btn-icon-left-lg": this.size == "lg" && this.hasButtonContent,
      };
    },
    rightIconClass() {
      return {
        "btn-icon": true,
        "btn-icon-right-sm": this.size == "sm" && this.hasButtonContent,
        "btn-icon-right-md": this.size == "md" && this.hasButtonContent,
        "btn-icon-right-lg": this.size == "lg" && this.hasButtonContent,
      };
    },
    hasButtonContent() {
      if (this.busy) return false;
      return !!this.$slots.default && !!this.$slots.default.length;
    },
    btnIconSize() {
      return null;
    },
  },
};
</script>

<style scoped>
/* General Button Styles */

button {
  display: flex;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  -webkit-appearance: none;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.fullwidth {
  width: 100%;
}

.fullwidth::v-deep span {
  margin: 0 auto;
}

.tertiary {
  background: transparent;
}

/* .tertiary:hover { */
  /* background:  !important; */
/* } */

.tertiary:disabled {
  opacity: 0.52;
  cursor: not-allowed;
}

/* 
  Button Sizes 

  We reduce original UI kit sizes by 1 pixel to account the border width
*/
.sm {
  padding: 5px 12px;
}

.md {
  padding: 8px 18px;
}

.lg {
  padding: 10px 24px;
}

.sm.secondary {
  padding: 4px 11px;
}

.md.secondary {
  padding: 7px 17px;
}

.lg.secondary {
  padding: 9px 23px;
}

/* Icon Gaps */
.sm.with-icon {
  column-gap: 4px;
}

.md.with-icon {
  column-gap: 6px;
}

.lg.with-icon {
  column-gap: 8px;
}

/* Disabled State */

button:disabled {
  opacity: 0.64;
  cursor: not-allowed;
}

/* Icon Size and Positions */
.btn-icon {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  display: block;
  font-size: 14px;
}

.md .btn-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

.lg .btn-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.icon-only.sm .btn-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

.icon-only.md .btn-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

/* Icon paddings */

button.sm.icon {
  padding: 4px;
}

button.md.icon {
  padding: 8px;
}

button.lg.icon {
  padding: 12px;
}

/* .loader .loading-icon {
  animation: loading 1s linear infinite;
} */

.button-content-wrap {
  display: contents;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>