<template>
  <div class="qrw-phone-input-picker">
    <div class="country-search-block" v-if="useBuiltInSearch">
      <BaseSearchInput v-model="search" :placeholder="placeholder" size="md" />
    </div>
    <div class="country-search-list-container" ref="scroller">
      <div class="country-search-list">
        <div
          class="country-search-item"
          :class="{
            active: item.id === selected,
          }"
          v-for="item in countries"
          :ref="item.id"
          :key="item.id"
          @click="selected = item.id"
        >
          <div class="search-item-left">
            <img class="country-flag" :src="item.flag" />
            <div class="qrw-label-lg country-name" :class="{
              bold: item.id === selected,
            }">
              {{ item.name }}
            </div>
          </div>

          <div class="qrw-label-lg country-code">
            {{ item.code}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneCodes from "@/assets/phone_codes.json";

export default {
  props: {
    placeholder: {
      type: String,
      default: "Search...",
    },
    defaultCountry: {
      type: String,
      default: "UA",
    },
    defaultPhone: {
      type: String,
      default: null
    },
    useBuiltInSearch: {
      type: Boolean,
      default: true,
    },
    searchValue: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      search: null,

      selected: 'UA'
    };
  },
  watch: {
    selected() {
      this.$emit('select', this.selected);
    }
  },
  methods: {
    scrollToSelectedCountry() {
      const scroller = this.$refs.scroller;
      const selectedCountry = this.$refs[this.selected][0];

      if (selectedCountry) {
        this.$nextTick(() => {
          scroller.scrollTop = selectedCountry.offsetTop - scroller.offsetTop - selectedCountry.offsetHeight;
        }); 
      }
    },
  },
  computed: {
    countries() {
      return PhoneCodes.map((item) => {
        const convertCountryNameToImageName = (name) => {
          return name.toLowerCase().replace(/\s/gi, '-');
        };

        return {
          id: item.iso,
          name: item.name,
          code: item.code,
          flag: `https://cdn.qrwaiter.com.ua/assets/flags/v2-wavy/${convertCountryNameToImageName(item.name)}.png`,
        };
      }).filter(item => {
        const query = this.searchValue || this.search;

        if (!query) {
          return true;
        }

        return item.name.toLowerCase().includes(query) || item.code.toLowerCase().includes(query);
      });
    },
  },
  mounted() {
    if (this.defaultPhone) {
      const rawPhone = this.defaultPhone.replace(/\(\)-\s/g, '');

      let longestPossibleCode = 0;
      let guessedCountry = null;

      //TODO: improve country code guessing and move it to utils
      for (const item of PhoneCodes) {
        if (rawPhone.startsWith(item.code) && item.code.length > longestPossibleCode) {
          longestPossibleCode = item.code.length;
          guessedCountry = item.iso;
        }
      }

      if (guessedCountry) this.selected = guessedCountry; 

      this.scrollToSelectedCountry();
    } else {
      if (this.defaultCountry) {
        this.selected = this.defaultCountry;
        this.scrollToSelectedCountry();
      }
    }
  },
};
</script>

<style scoped>
.qrw-phone-input-picker {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.country-search-block {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.country-search-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 8px 16px 16px 16px;
  row-gap: 8px;
}

.country-search-list-container {
  display: flex;
  height: 100%;
  overflow: scroll;
  flex-direction: column;
}

.country-search-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 11px 24px 11px 22px;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid var(--qrw-border-base-tertiary);
}

.search-item-left {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}

.country-search-item.active {
  border: 2px solid var(--qrw-border-base-primary);
  padding: 10px 24px 10px 22px;
}

.country-name {
  color: var(--qrw-content-base-primary);
}


.country-code {
  color: var(--qrw-content-base-tertiary);
}

.country-flag {
  display: block;
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
}
</style>