<template>
  <button
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @touchstart="hovered = true"
    @touchend="hovered = false"
    :class="buttonClass"
    :style="buttonStyle"
    :disabled="disabled"
    @mouseup="hovered = false"
    @click="onClicked"
  >
    <BaseIcon
      :size="iconSize"
      :icon="icon"
    />
  </button>
</template>

<script>
// import utils from "../../utils";

import buttonStylesBuilder from './buttonStylesBuilder'

export default {
  props: {
    size: {
      type: String,
      default: 'xs',
      validator: v => ['xs', 'md', 'sm', 'lg'].includes(v)
    },
    bgColor: {
      type: String,
      default: 'background-base-primary'
    },
    textColor: {
      type: String,
      default: 'content-base-primary'
    },
    textHoverColor: String,
    borderColor: String,
    borderHoverColor: String,
    hoverColor: String,
    variant: {
      type: String,
      default: 'primary',
      validator: v => ['primary', 'secondary', 'tertiary', 'fill', 'stroke', 'ghost', 'outlined', 'gray'].includes(v)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      hovered: false
    }
  },
  methods: {
    onMouseEnter() {
      this.hovered = true
    },
    onMouseLeave() {
      this.hovered = false
    },
    onClicked() {
      if (this.isMobile) {
        this.hovered = false
      }

      this.$emit('click')
    }
  },
  computed: {
    iconSize() {
      return {
        xs: 'xs',
        sm: 'xs',
        md: 'sm',
        lg: 'sm2'
      }[this.size]
    },
    buttonPaddings() {
      const paddingsMap = {
        xs: [4, 4],
        sm: [8, 8],
        md: [10, 10],
        lg: [12, 12]
      }
      let [top, right] = paddingsMap[this.size] || paddingsMap.xs
      let [bottom, left] = paddingsMap[this.size] || paddingsMap.xs

      if (this.variant == 'secondary' || this.variant == 'stroke' || this.variant == 'outlined') {
        //border should not be accounted
        top -= 1
        bottom -= 1
        right -= 1
        left -= 1
      }

      return {
        padding: `${top}px ${right}px ${bottom}px ${left}px`
      }
    },
    variantBasedStyles() {
      return buttonStylesBuilder({
        bgColor: this.bgColor,
        textColor: this.textColor,
        borderColor: this.borderColor,
        hoverColor: this.hoverColor,
        variant: this.variant,
        hovered: this.hovered,
        textHoverColor: this.textHoverColor,
        borderHoverColor: this.borderHoverColor
      })
    },

    buttonStyle() {
      return {
        ...this.buttonPaddings,
        ...this.variantBasedStyles
      }
    },
    buttonClass() {
      return {
        'qrw-icon-button': true,
        [`size-${this.size}`]: true,
        [`qrw-label-${this.size}`]: true,
        bold: this.variant === 'primary',
        'w-100': this.fullWidth,
        disabled: this.disabled
      }
    }
  }
}
</script>

<style scoped>
.qrw-icon-button {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  -webkit-appearance: none;
  align-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.size-xs {
  border-radius: 6px;
}

.size-sm {
  border-radius: 8px;
}

.size-md {
  border-radius: 10px;
}

.size-lg {
  border-radius: 12px;
}
</style>
