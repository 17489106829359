<template>
  <div class="qrw-common-input-container qrw-search-input-container">
    <BaseIcon
      :size="iconSize"
      icon="search"
      :class="
        [
        `search-icon`, size, 
          value || isFocused
          ? 'qrw-text-content-base-primary'
          : 'qrw-text-content-base-tertiary'
        ]
      "
    />
    <input
      :type="type"
      :value="value"
      :disabled="disabled"
      ref="inputEl"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @input="onInput"
      class="qrw-common-input-element"
      :class="{
        [`qrw-paragraph-${size}`]: true,
        'state-error': invalid,
        [`size-${size}`]: true,
        'state-disabled': disabled,
        'state-focus': isFocused,
      }"
      :style="inputStyles"
      :placeholder="placeholder"
    />
    <BaseIcon
      :size="iconSize"
      class="qrw-text-content-base-tertiary clear-icon"
      :class="{
        [size]: true
      }"
      icon="x-close"
      @click="clearInput"
      v-if="value"
    />
  </div>
</template>

<script>
import './inputCommons.scss'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 'sm',
      validator: v => ['sm', 'md', 'lg'].includes(v)
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocused: false
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },
    getInputElement() {
      return this.$refs.inputEl
    },
    clearInput() {
      this.$emit('input', null)

      //focus input after clearing
      this.getInputElement().focus()
    }
  },
  computed: {
    iconSize() {
      return {
        sm: 'xs',
        md: 'sm',
        lg: 'sm2'
      }[this.size]
    },
    inputStyles() {
      //initial input padding + icon size
      const iconPaddings = {
        sm: 10 + 16,
        md: 12 + 20,
        lg: 16 + 24
      }

      return {
        'padding-left': `${iconPaddings[this.size]}px`,
        //add right padding only if close icon is present
        'padding-right': this.value ? `${iconPaddings[this.size]}px` : null
      }
    }
  }
}
</script>

<style>
.clear-icon {
  cursor: pointer;
  position: absolute !important;
}

.search-icon {
  position: absolute !important;
}

.search-icon.sm {
  left: 8px;
}

.search-icon.md {
  left: 10px;
}

.search-icon.lg {
  left: 12px;
}

.clear-icon.sm {
  right: 8px;
}

.clear-icon.md {
  right: 10px;
}

.clear-icon.lg {
  right: 12px;
}
</style>