<template>
  <div
    class="tabs-wrapper"
    :style="{
      'background': `var(--${defaultColorVariable})`,
      'border-radius': noCorners ? 0 : '8px',
    }"
  >
    <div
      class="base-tabs"
      ref="wrapper"
      :style="{
        'background': `var(--${defaultColorVariable})`,
        'border-radius': noCorners ? '0' : '8px 8px 0 0',
      }"
    >
      <div
        class="tab-container-item"
        v-for="(tab, index) in tabs"
        :key="`${index}-${tab.value}`"
        @click="$emit('input', tab.value)"
        :class="{
          'tab-active': tab.value == value,
        }"
        :style="getTabContainerStyle(tab, index)"
      >
        <div
          :class="{
            'tab': true,
            [`tab-${size}`]: true,
            'bold': tab.value === value,
            [`qrw-label-${mappedTextSize}`]: true,
          }"
          :ref="`tab${index}`"
          :style="getTabStyle(index)"
        >
          <BaseIcon
            :class="`tab-icon-${size}`"
            :size="null"
            :icon="makeupIconName(tab)"
            v-if="tab.icon"
          />
          <span
            :class="{
              'tab-clamping-text': !doesFitView,
            }"
            v-if="tab.text"
            >{{ tab.text }}</span
          >
        </div>
      </div>
    </div>

    <div
      class="tabs-content"
      :class="contentClass"
      :style="contentBlockStyle"
      v-if="hasContentSlot"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import utils from '../utils';

export default {
  props: {
    tabs: Array,
    value: [Number, String, Boolean],
    size: {
      type: String,
      default: "sm",
    },
    contentClass: {
      type: String,
      default: null,
    },
    selectedColor: {
      type: String,
      default: "grey-1",
    },
    defaultColor: {
      type: String,
      default: "background-base-tertiary",
    },
    defaultTextColor: {
      type: String,
      default: 'content-base-primary'
    },
    selectedTextColor: {
      type: String,
      default: 'content-base-inverse-primary'
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    noCorners: {
      type: Boolean,
      default: false,
    },
    waterfall: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "tabs.length"() {
      // this.$nextTick(this.checkWidth);
    },
  },
  data() {
    return {
      tabWidth: 150,
      doesFitView: true,
    };
  },
  computed: {
    currentTabIndex() {
      return this.tabs.findIndex((t) => t.value == this.value);
    },
    cssBorderRadius() {
      return (
        {
          sm: "4px",
          md: "6px",
          lg: "8px",
        }[this.size] || "6px"
      );
    },
    contentBlockStyle() {
      // if (this.currentTabIndex == 0)

      if (this.noCorners) return;

      return {
        "border-bottom-right-radius": this.cssBorderRadius,
        "border-bottom-left-radius": this.cssBorderRadius,
        'background': `var(--${utils.getVariableForColorName(this.selectedColor)})`,
        "border-top-right-radius":
          this.currentTabIndex != this.tabs.length - 1
            ? this.cssBorderRadius
            : "0px",
        "border-top-left-radius":
          this.currentTabIndex != 0 ? this.cssBorderRadius : "0px",
      };
    },
    hasContentSlot() {
      return !!this.$slots.default;
    },
    mappedTextSize() {
      return {
        'sm': 'xs',
        'md': 'sm',
        'lg': 'md'
      }[this.size];
    },
    defaultColorVariable() {
      return utils.getVariableForColorName(this.defaultColor);
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.$nextTick(() => {
    //     setTimeout(() => {
    //       console.log(`true stuff`);
          
    //     }, 250);
    //   });
    // });
    this.$nextTick(this.checkWidth);
  },
  methods: {
    getScrollContainer() {
      return this.$refs.wrapper;
    },
    checkWidth() {
      for (let i = 0; i < this.tabs.length; i++) {
        const nodes = this.$refs[`tab${i}`];

        const el = nodes[0];

        const lineHeight = +window
          .getComputedStyle(el)
          .lineHeight.replace("px", "");
        const realHeight = +window
          .getComputedStyle(el)
          .height.replace("px", "");

        if (Math.abs(realHeight - lineHeight) > 3) {
          this.doesFitView = false;
          break;
        }
      }

      if (!this.doesFitView) {
        const wrapper = this.getScrollContainer();

        const scrollArrowSize = 40;
        const freeSpace = 40;
        this.tabWidth = wrapper.offsetWidth - scrollArrowSize - freeSpace;
      }

      // return;
      // const wrapperEl = this.$refs.wrapper;

      // const MIN_UX_TAB_WIDTH = 130;

      // this.doesFitView =
      //   wrapperEl.offsetWidth / this.tabs.length >= MIN_UX_TAB_WIDTH;

      // if (this.doesFitView) {
      //   return;
      // }

      // //how much of the tab width should be left at right side
      // //to show user that the tabs can be scrolled
      // const UX_TAIL_PERCENT = 15;

      // //const theoreticalTabWidth = wrapperEl.scrollWidth / this.tabs.length;
      // const tabsViewWidth = wrapperEl.offsetWidth;

      // const desiredTabWidth =
      //   (tabsViewWidth * ((100 - UX_TAIL_PERCENT) / 100)) / this.tabs.length;

      // this.tabWidth = Math.round(desiredTabWidth);
    },
    getTabStyle(index) {
      const selected_index = this.currentTabIndex;
      const style = {
        background: `var(--${utils.getVariableForColorName(this.defaultColor)})`,
        color: `var(--${utils.getVariableForColorName(this.defaultTextColor)})`
      };

      if (index == selected_index) {
        style.background = `var(--${utils.getVariableForColorName(this.selectedColor)})`;
        style.color = `var(--${utils.getVariableForColorName(this.selectedTextColor)})`
      }

      if (selected_index === -1) return {};

      // left tab
      if (index == selected_index - 1 && !this.waterfall)
        style["border-bottom-right-radius"] = this.cssBorderRadius;
      // right tab
      if (index == selected_index + 1 && !this.waterfall)
        style["border-bottom-left-radius"] = this.cssBorderRadius;

      // regular
      if (index == selected_index) {
        style["border-radius"] = this.waterfall
          ? `0 0 ${this.cssBorderRadius} ${this.cssBorderRadius}`
          : `${this.cssBorderRadius} ${this.cssBorderRadius} 0 0`;
      }

      // compute needed tab width if we are using scrollable tabs
      if (!this.doesFitView && !this.noScroll) {
        // style["min-width"] = `${this.tabWidth}px`;
        style['width'] = `100%`;
        style["max-width"] = `${this.tabWidth}px`;
      } else {
        style["width"] = "100%";
      }

      return style;
    },
    getTabContainerStyle(tab, index) {
      let base = {
        background: `var(--${utils.getVariableForColorName(this.defaultColor)})`,
        color:
          tab.value == this.value
            ? `var(--${utils.getVariableForColorName(tab.activeTextColor || "content-base-primary")})`
            : `var(--${utils.getVariableForColorName(tab.textColor || "content-base-primary")})`,
      };

      const rightRadiusPart = this.waterfall
        ? {
            "border-bottom-right-radius": this.cssBorderRadius,
          }
        : {
            "border-top-right-radius": this.cssBorderRadius,
          };

      const leftRadiusPart = this.waterfall
        ? {
            "border-bottom-left-radius": this.cssBorderRadius,
          }
        : {
            "border-top-left-radius": this.cssBorderRadius,
          };

      if (index > 0 && index < this.tabs.length - 1) {
        base = {
          ...base,
          ...rightRadiusPart,
          ...leftRadiusPart,
        };
      } else {
        if (index == 0) {
          base = { ...base, ...rightRadiusPart };
          if (!this.noCorners) {
            base = { ...base, ...leftRadiusPart };
          }
        }
        if (index == this.tabs.length - 1) {
          base = { ...base, ...leftRadiusPart };

          if (!this.noCorners) {
            base = { ...base, ...rightRadiusPart };
          }
        }
      }

      return base;
    },
    makeupIconName(tab) {
      if (tab.icon.includes("regular") || tab.icon.includes("bold"))
        return tab.icon;

      return this.value === tab.value
        ? `${tab.icon}-bold`
        : `${tab.icon}-regular`;
    },
  },
};
</script>

<style scoped>
.base-tabs {
  display: flex;
  width: 100%;

  /* background: var(--white-1-color); done */
  overflow: hidden;
  position: relative;
  overflow-x: visible;
}

.base-tabs.tab-sm-bord,
.tab-sm-bord .tab-active {
  border-radius: 4px;
}

.base-tabs.tab-lg-bord,
.tab-lg-bord .tab-active {
  border-radius: 8px;
}

.tab-container-item {
  display: flex;
  /* background: var(--white-9-color); */
  width: 100%;
}

.tab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  text-align: center;
  align-items: center;
  column-gap: 6px;
}

.tab-clamping-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tab-sm {
  padding: 5px 12px;
  column-gap: 4px;
}

.tab-md {
  padding: 8px 18px;
}

.tab-lg {
  padding: 10px 24px;
}

.tab-icon-sm {
  font-size: 14px;
}

.tab-icon-md {
  font-size: 16px;
}

.tab-icon-lg {
  font-size: 20px;
}

.tab-active {
  /* background: var(--white-7-color); */
  /* color: var(--grey-10-color); */
  font-weight: bold;
}

.tab-active-borders {
  border-radius: 6px 6px 0 0;
}

.tabs-content {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}
</style>