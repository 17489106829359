<template>
  <div
    class="tabs-switcher"
    :class="{
      [themeBg]: true,
      'no-corners': noCorners,
      disabled,
      vertical: vertical || forceVerticalFlexDirection,
      [`tabs-switcher-${size}`]: true,
    }"
    :style="{
      width: cssWidth,
    }"
  >
    <div
      :class="{
        tab: true,
        [`qrw-label-${textSize || calculatedTextSzie}`]: true,
        [`tab-${size}`]: true,
        'tab-active':
          (isSelected(tab.value) ||
            (tab.hidden_values && tab.hidden_values.includes(value))) &&
          themeBg !== 'dark',
        'tab-active-dark': themeBg == 'dark' && isSelected(tab.value),
        'tab-no-corners': noCorners,
        'tab-min-width': minWidth,
        'tab-vertical': vertical,
        'tab-with-icon': !!tab.icon,
        'tab-icon-only': tab.icon && !tab.text,
        'bold': isSelected(tab.value)
      }"
      :ref="`tab${index}`"
      v-for="(tab,index) in tabs"
      :key="tab.value"
      @click="onTabClicked(tab)"
    >
      <BaseIcon
        :icon="makeupIconName(tab)"
        :size="null"
        :style="iconStyle"
        v-if="tab.icon"
      />
      <template v-if="tab.text">{{tab.text}}</template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    value: [Object, String, Date, Number, Array, Boolean],
    size: {
      type: String,
      default: 'md'
    },
    textSize: {
      type: String
    },
    theme: String,
    noCorners: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: Boolean,
      default: false
    },
    cssWidth: {
      type: String,
      default: '100%'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    withAdaptiveDirection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      forceVerticalFlexDirection: false
    }
  },
  watch: {
    'tabs.length'() {
      this.checkIfTabsFit()
    }
  },
  methods: {
    onTabClicked(tab) {
      if (this.disabled) return

      let inputValue = null

      if (Array.isArray(this.value)) {
        const itemIndex = this.value.findIndex(v => v === tab.value)

        if (itemIndex === -1) {
          inputValue = [...this.value, tab.value]
        } else {
          inputValue = this.value.filter(v => v !== tab.value)
        }
      } else {
        inputValue = tab.value
      }

      this.$emit('input', inputValue)
    },
    isSelected(val) {
      if (Array.isArray(this.value)) {
        return this.value.includes(val)
      } else {
        return this.value === val
      }
    },
    makeupIconName(tab) {
      if (tab.icon.includes('regular') || tab.icon.includes('bold')) return tab.icon

      return this.isSelected(tab.value) ? `${tab.icon}-bold` : `${tab.icon}-regular`
    },
    checkIfTabsFit() {
      if (this.vertical) return
      if (!this.withAdaptiveDirection) return

      this.forceVerticalFlexDirection = false

      for (let i = 0; i < this.tabs.length; i++) {
        const el = this.$refs[`tab${i}`][0]

        const lineHeight = +window.getComputedStyle(el).lineHeight.replace('px', '')
        const realHeight = +window.getComputedStyle(el).height.replace('px', '')

        console.log(`Checking tab ${i}: ${lineHeight} < ${realHeight}`)

        if (Math.abs(realHeight - lineHeight) > 3) {
          this.forceVerticalFlexDirection = true
          return
        }
      }
    }
  },
  computed: {
    themeBg() {
      return this.theme == 'dark' ? 'dark' : ''
    },
    iconStyle() {
      const style = {}

      const mapping = {
        sm: 14,
        md: 16,
        lg: 20
      }

      style['font-size'] = `${mapping[this.size]}px`

      return style
    },
    calculatedTextSzie() {
      return {
        sm: 'md',
        md: 'lg',
        lg: 'lg'
      }[this.size]
    }
  },
  mounted() {
    this.$nextTick(this.checkIfTabsFit)
  }
}
</script>

<style scoped>
.tabs-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: var(--v1-grey-4-color);
  color: var(--v1-grey-7-color);
  padding: 4px;
  box-sizing: border-box;
}

.tabs-switcher-sm {
  border-radius: 8px;
}

.tabs-switcher-md {
  border-radius: 10px;
}

.tabs-switcher-lg {
  border-radius: 10px;
}

.tabs-switcher.vertical {
  flex-direction: column;
}

.tab-sm:has(.tabs-switcher) {
  border-radius: 4px;
}

.tabs-switcher.dark {
  background: var(--v1-grey-9-color);
  color: var(--v1-grey-6-color);
}

.tab-active-dark {
  background: var(--v1-grey-3-color);
  font-weight: bold;
  color: var(--v1-grey-10-color) !important;
}

.tabs-switcher.dark .tab {
  color: var(--v1-grey-3-color);
}

.tab {
  cursor: pointer;
  padding: 8px;
  text-align: center;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

.tab-vertical {
  box-sizing: border-box;
}

.tab-min-width {
  min-width: 150px;
}

.tab-sm {
  padding: 6px 12px;
  border-radius: 8px;
  column-gap: 4px;
}

.tab-md {
  padding: 8px 18px;
  column-gap: 6px;
  border-radius: 10px;
}

.tab-lg {
  padding: 10px 24px;
  border-radius: 10px;
  width: 100%;
  column-gap: 8px;
}

.tab-sm.tab-with-icon {
  padding-left: 10px;
}
.tab-md.tab-with-icon {
  padding-left: 16px;
}
.tab-lg.tab-with-icon {
  padding-left: 22px;
}

.tab-sm.tab-icon-only {
  padding: 5px;
}
.tab-md.tab-icon-only {
  padding: 8px;
}
.tab-lg.tab-icon-only {
  padding: 10px;
}

.tab-active {
  background: var(--qrw-background-base-primary);
  color: var(--qrw-content-base-primary);

  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.tab-no-corners {
  border-radius: 0;
}

.tabs-switcher.no-corners {
  border-radius: 0;
}

.tabs-switcher.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>