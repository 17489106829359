<template>
  <div class="base-input-wrapper" :class="inputWrapperClass">
    <BaseIcon
      :icon="leftIcon || icon"
      @click="$emit('iconClicked', 'left')"
      :class="leftIconClass"
      v-if="
        (leftIcon || icon) && (iconPosition == 'left' || iconPosition == 'both')
      "
    ></BaseIcon>
    <input
      @input="(ev) => $emit('input', ev.target.value)"
      @keyup="(ev) => $emit('keyup', ev)"
      @keydown="(ev) => $emit('keydown', ev)"
      @keypress="isNumber($event)"
      @blur="onInputBlur"
      @focus="onInputFocus"
      :value="value"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      class="base-input"
      :inputmode="inputmode"
      :pattern="pattern"
      :class="inputClass"
      :style="inputStyle"
      :id="inputId"
      ref="baseInputElement"
      :required="required"
      :readonly="readonly"
      :disabled="disabled"
      v-mask="mask"
    />
    <BaseIcon
      :icon="rightIcon || icon"
      @click="$emit('iconClicked', 'right')"
      :class="rightIconClass"
      v-if="
        (rightIcon || icon) &&
        (iconPosition == 'right' || iconPosition == 'both')
      "
    ></BaseIcon>
  </div>
</template>

<script>
const preventPageScrollingHandler = (e) => {
  e.preventDefault();
};

export default {
  data() {
    return {
      focused: false,
    };
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    position: {
      type: String,
      default: "left",
    },
    placeholder: {
      type: [String, Number, Object, Boolean],
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    leftIcon: {
      type: String,
    },
    rightIcon: {
      type: String,
    },
    iconPosition: {
      type: String,
      default: "left",
    },
    size: {
      type: String,
      default: "sm",
    },
    hasUnit: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    value: [String, Number, Object],
    valid: {
      type: Boolean,
      default: true,
    },
    inputId: {
      type: String,
      default: null,
    },
    mask: {
      type: String,
      default: null,
    },
    leftIconColor: {
      type: String,
      default: null,
    },
    rightIconColor: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    overrideColor: String,
    pattern: String,
    inputmode: {
      type: String,
      default: "text",
    },
    noScrollFreezeOnFocus: Boolean,
  },
  methods: {
    getInputElement() {
      return this.$refs.baseInputElement;
    },
    isNumber(evt) {
      this.$emit("keypress", evt);

      if (this.type != "number") {
        return true;
      }

      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onInputFocus() {
      this.$emit("focus");
      this.focused = true;

      if (!this.noScrollFreezeOnFocus) {
        //fix for fucking safari, see https://stackoverflow.com/questions/56351216/ios-safari-unwanted-scroll-when-keyboard-is-opened-and-body-scroll-is-disabled
        window.addEventListener("touchmove", preventPageScrollingHandler, {
          passive: false,
        });
        window.document.body.addEventListener("touchmove", preventPageScrollingHandler, {
          passive: false,
        });
        window.addEventListener("click", this.removeScrollPrevention.bind(this), {
          passive: false,
        });
      }
    },
    removeScrollPrevention() {
      window.removeEventListener("touchmove", preventPageScrollingHandler);
      window.document.body.removeEventListener("touchmove", preventPageScrollingHandler);

      window.removeEventListener("click", this.removeScrollPrevention);
    },
    onInputBlur() {
      this.$emit("blur");
      this.focused = false;

      if (!this.noScrollFreezeOnFocus) {
       this.removeScrollPrevention();
      }
    },
  },
  computed: {
    inputClass() {
      return {
        "state-disabled": this.disabled,

        [`position-${this.position}`]: true,

        "state-error": !this.valid,

        [`size-${this.size}`]: true,

        "with-unit-sm": this.hasUnit && this.value.length == 1,
        "with-unit-md": this.hasUnit && this.value.length == 2,
        "with-unit-lg": this.hasUnit && this.value.length > 2,

        [`qrw-paragraph-${this.size}`]: true,

        "webkit-color-fix": this.disabled && this.value,
      };
    },
    inputStyle() {
      if (this.overrideColor) {
        return {
          color: `var(--${this.overrideColor}-color)`,
          "-webkit-text-fill-color": `var(--${this.overrideColor}-color)`,
        };
      } else {
        return {};
      }
    },
    inputWrapperClass() {
      return {
        "state-disabled": this.disabled,
        "state-error": !this.valid,
        [`size-${this.size}`]: true,
        "full-width": this.fullWidth,
        "state-focus": this.focused && this.valid,
      };
    },
    leftIconClass() {
      return {
        "input-icon": true,
        "size-sm": this.size === "lg",
        opacity: !this.value || this.disabled,
        "input-icon-left": this.icon,
        disabled: this.readonly,
        [`${this.leftIconColor || this.iconColorBasedOnState}-color`]: true,
      };
    },
    rightIconClass() {
      return {
        "input-icon": true,
        "size-sm": this.size === "lg",
        opacity: !this.value || this.disabled,
        "input-icon-right": this.icon,
        disabled: this.readonly,
        [`${this.rightIconColor || this.iconColorBasedOnState}-color`]: true,
      };
    },
    iconColorBasedOnState() {
      if (!this.value) return "qrw-content-base-tertiary";
      if (this.disabled) return "qrw-content-base-tertiary";
      if (!this.valid && this.value) return "qrw-content-error-primary";

      return "qrw-content-base-primary";
    },
  },
};
</script>


<style scoped>
/* Base classes */
.base-input-wrapper {
  display: flex;
  width: 100%;
  background: var(--qrw-background-base-primary);
  border-radius: 4px;
  border: 1px solid var(--qrw-border-base-secondary);
  box-sizing: border-box;
  align-items: center;
  column-gap: 8px;
  flex-direction: row;
}

.base-input {
  width: 100%;
  outline: none;

  background: transparent;
  color: var(--qrw-content-base-primary);

  border-radius: inherit;
  border: none;
  box-sizing: border-box;

  height: auto;

  padding: 0;

  -webkit-appearance: none;
}

/* Position / text alignment */
.base-input.position-left {
  text-align: left;
}

.base-input.position-center {
  text-align: center;
}

.base-input.position-right {
  text-align: right;
}

/* Disabled */
.base-input-wrapper.state-disabled {
  border: none;
  background: var(--qrw-background-base-tertiary);
}

.base-input.state-disabled,
.base-input:disabled {
  color: var(--qrw-content-base-primary) !important;
  opacity: 1;
  cursor: not-allowed;
}

/* FUCK_SAFARI: Safari overrides placeholder and disabled input color,
  so we must use webkit-specific rule to override it by ourselves
  
  P.S. 'currentcolor' as a value does not work here too*/
.base-input.webkit-color-fix {
  -webkit-text-fill-color: var(--qrw-content-base-primary);
}

/* Placeholder */
.base-input::placeholder,
.base-input:disabled::placeholder,
.base-input::-webkit-input-placeholder {
  /* color for placeholders are the same for enabled and disabled input */
  color: var(--qrw-content-base-tertiary) !important;
}

/* Error / invalid state */
.base-input-wrapper.state-error {
  border: 1px solid var(--qrw-border-error-primary);
}

.base-input.state-error {
  color: var(--qrw-content-error-primary);
}

/* Sizes */
.base-input-wrapper.size-sm {
  padding: 5px 10px;
}

.base-input-wrapper.size-sm.state-disabled {
  padding: 6px 10px;
}

.base-input-wrapper.size-md {
  padding: 7px 12px;
}

.base-input-wrapper.size-md.state-disabled {
  padding: 8px 12px;
}

.base-input-wrapper.size-lg {
  padding: 9px;
}

.base-input-wrapper.size-lg.state-disabled {
  padding: 10px;
}

/* Focus */
.base-input-wrapper.state-focus {
  border: 1px solid var(--qrw-border-base-primary);
}

/* Full width helper */
.base-input-wrapper.full-width {
  width: 100%;
}

/* Unit input */
.base-input.with-unit-lg {
  width: 42px;
}

.base-input.with-unit-sm {
  width: 16px;
}

.base-input.with-unit-md {
  width: 32px;
}

/* Icons */
.input-icon {
  cursor: pointer;
}
</style>