<template>
  <div class="qrw-common-input-container qrw-icon-input-container">
    <BaseIcon
      :size="iconSize"
      :icon="icon"
      :class="
        [value || isFocused
          ? 'qrw-text-content-base-primary'
          : 'qrw-text-content-base-tertiary', 'input-icon', size]
      "
    />
    <input
      :type="type"
      :value="value"
      :disabled="disabled"
      @focus="isFocused = true; $emit('focus')"
      @blur="isFocused = false; $emit('blur')"
      @input="onInput"
      :style="inputStyles"
      class="qrw-common-input-element"
      :class="{
        [`qrw-paragraph-${size}`]: true,
        'state-error': invalid,
        [`size-${size}`]: true,
        'state-disabled': disabled,
        'state-focus': isFocused,
      }"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import './inputCommons.scss'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 'sm',
      validator: v => ['sm', 'md', 'lg'].includes(v)
    },
    invalid: {
      type: Boolean,
      default: false
    },
    icon: String
  },
  data() {
    return {
      isFocused: false
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    }
  },
  computed: {
    iconSize() {
      return {
        sm: 'sm',
        md: 'sm2',
        lg: 'sm4'
      }[this.size]
    },
    inputStyles() {
      //initial input padding + icon size
      const iconPaddings = {
        sm: 6 * 2 + 16,
        md: 8 * 2 + 20,
        lg: 10 * 2 + 24
      }

      return {
        'padding-left': `${iconPaddings[this.size]}px`
      }
    }
  }
}
</script>

<style scoped>
.qrw-icon-input-container {
  column-gap: 6px;
  align-items: center;
}

.qrw-icon-input-container.size-sm {
  padding-left: 5px;
}

.qrw-icon-input-container.size-md {
  padding-left: 7px;
}

.qrw-icon-input-container.size-lg {
  padding-left: 9px;
}

.input-icon {
  position: absolute !important;
}

.input-icon.sm {
  left: 6px;
}

.input-icon.md {
  left: 8px;
}

.input-icon.lg {
  left: 10px;
}
</style>