<template>
  <div class="qrw-digit-inputs-group">
    <input
      type="text"
      class="qrw-digit-input"
      :class="{[size]: true, [`qrw-paragraph-${size}`]: true, invalid, success, disabled}"
      v-for="n in length"
      :key="n"
      :value="buffer[n-1]"
      :disabled="disabled"
      @keydown="ev => onKeydown(n-1, ev)"
      placeholder="_"
      :ref="`input_${n-1}`"
      @input="ev => onInput(n-1, ev)"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    length: {
      type: Number,
      default: 4
    },
    size: {
      type: String,
      default: 'sm'
    },
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buffer: [],

      skipNextValueUpdate: false
    }
  },
  watch: {
    value() {
      if (!this.value) {
        if (!this.skipNextValueUpdate) this.reset()

        this.skipNextValueUpdate = false
      }
    }
  },
  methods: {
    onInput(index, event) {
      this.$set(this.buffer, index, (event.target.value || '').charAt(0))

      if (this.buffer[index] && index < this.length - 1) {
        this.focus(index + 1)
      }

      this.$emit('digitInput', index, this.buffer[index])

      const allValuesPresent = this.buffer.every(val => val !== null && val !== '')

      if (allValuesPresent) {
        this.$emit('input', this.buffer.join(''))
      } else {
        this.$emit('input', null)
        this.skipNextValueUpdate = true
      }
    },
    focus(index) {
      this.$refs['input_' + index][0].focus()

      this.$emit('refocus', index)
    },
    onKeydown(index, event) {
      const key = event.keyCode || event.charCode

      //check for backspace
      if (!this.buffer[index] && (key === 8 || key === 46)) {
        this.$set(this.buffer, index, null)
        if (index > 0) this.focus(index - 1)
        this.$emit('input', null)
      }
    },
    reset() {
      const newBuffer = []

      for (let i = 0; i < this.length; i++) newBuffer.push(null)

      this.buffer = newBuffer
    }
  },
  mounted() {
    this.reset()
  }
}
</script>

<style scoped>
.qrw-digit-inputs-group {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}

.qrw-digit-input {
  -webkit-appearance: none;
  outline: none;

  border: 1px solid var(--qrw-border-base-tertiary);

  color: var(--qrw-content-base-primary);
  text-align: center;

  box-sizing: border-box;
}

.qrw-digit-input.invalid {
  border: 1px solid var(--qrw-border-error-primary);
}

.qrw-digit-input.success {
  border: 1px solid var(--qrw-border-success-primary);
}

.qrw-digit-input.disabled {
  background: var(--qrw-border-base-tertiary-10);
  cursor: not-allowed;
}

.qrw-digit-input::placeholder,
.qrw-digit-input:disabled::placeholder,
.qrw-digit-input::-webkit-input-placeholder {
  /* color for placeholders are the same for enabled and disabled input */
  color: var(--qrw-content-base-tertiary) !important;
}

.qrw-digit-input:focus {
  border: 1px solid var(--qrw-border-base-primary);
}

.qrw-digit-input.sm {
  padding: 6px;
  border-radius: 8px;
  min-width: 32px;
  width: 32px;
}

.qrw-digit-input.md {
  padding: 8px;
  border-radius: 10px;
  min-width: 40px;
  width: 40px;
}

.qrw-digit-input.lg {
  padding: 10px;
  border-radius: 12px;
  min-width: 48px;
  width: 48px;
}
</style>