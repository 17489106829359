<template>
  <div class="qrw-progress-bar" :class="{[size]: true}">
    <div class="progress-bar-filler" :style="{
        width: progressBarWidth
    }"></div>
  </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'sm'
        },
        value: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        }
    },
    computed: {
        progressBarWidth() {
            return `${this.value / this.max * 100}%`;
        }
    }
}
</script>

<style scoped>
.qrw-progress-bar {
    position: relative;
    background-color: var(--qrw-border-base-tertiary);
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    width: 100%;
}

.progress-bar-filler {
    background-color: var(--qrw-border-base-primary);
}

.qrw-progress-bar.sm, .qrw-progress-bar.sm .progress-bar-filler{
    height: 4px;
    border-radius: 2px;
}

.qrw-progress-bar.md, .qrw-progress-bar.md .progress-bar-filler{
    height: 6px;
    border-radius: 3px;
}

.qrw-progress-bar.lg, .qrw-progress-bar.lg .progress-bar-filler{
    height: 8px;
    border-radius: 4px;
}
</style>