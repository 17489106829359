<template>
  <div
    class="base-beta-checkbox-container"
    :class="{ [size]: true }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @click="onClick"
  >
    <div class="base-beta-checkbox-padder" :class="{ [size]: true }">
      <div
        class="base-beta-checkbox"
        :class="{ [cell]: true, active: value, [size]: true }"
      >
        <div
          class="base-beta-checkbox-cell bold"
          :class="iconColorClass"
          v-if="showCell"
        >
          <!-- <BaseIcon
          :size="null"
          :style="checkboxCellIconStyle"
          :icon="checkboxCellIcon"
          v-show="value || isHovered"
          v-if="iconColorClass"
        /> -->
          <img
            class="checkbox-icon"
            :src="`https://cdn.qrwaiter.com.ua/assets/ui/${cell}-${
              !value ? 'hover' : 'active'
            }.svg`"
            v-show="value || isHovered"
            v-if="iconColorClass"
          />
        </div>
      </div>
    </div>

    <div
      :class="{
        [`qrw-label-${size}`]: true,
        bold: value,
        [value || isHovered
          ? `qrw-text-content-base-primary`
          : `qrw-text-content-base-secondary`]: true,
      }"
      v-if="hasContent"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    readonly: {
      type: Boolean,
      default: false,
    },
    cell: {
      type: String,
      validator: (v) => ["solid", "check", "cross", "plus"].includes(v),
      default: "solid",
    },
    size: {
      type: String,
      default: "lg",
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    onClick() {
      if (!this.readonly) {
        this.$emit("input", !this.value);
      }

      this.isHovered = false;
    },
  },
  computed: {
    hasContent() {
      return !!this.$slots.default;
    },
    iconColorClass() {
      if (this.cell == "solid") return null;

      if (this.value) {
        return `qrw-text-content-base-inverse-primary`;
      }

      if (this.isHovered) {
        return this.cell == "cross"
          ? "cell-no-background qrw-text-content-error-primary"
          : "cell-no-background qrw-text-content-success-primary";
      }

      return null;
    },
    showCell() {
      if (this.cell == "solid") return !!this.value;

      return this.value || this.isHovered;
    },
    checkboxCellIconStyle() {
      const sizes = {
        sm: 8,
        md: 10,
        lg: 12,
      };

      return {
        "font-size": `${sizes[this.size]}px`,
      };
    },
    checkboxCellIcon() {
      return {
        solid: null,
        check: "check",
        cross: "x",
        plus: "plus",
      }[this.cell];
    },
  },
};
</script>

<style scoped>
.base-beta-checkbox-container {
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: row;

  cursor: pointer;
}

.base-beta-checkbox-container.sm {
  column-gap: 4px;
}

.base-beta-checkbox-container.md {
  column-gap: 6px;
}
.base-beta-checkbox-container.lg {
  column-gap: 8px;
}

.base-beta-checkbox {
  display: flex;

  position: relative;

  box-sizing: border-box;

  border-radius: 4px;

  width: 100%;
  height: 100%;

  border: 1.5px solid var(--qrw-border-base-secondary);

  cursor: pointer;

  align-items: center;
  justify-content: center;
}

/* .base-beta-checkbox.sm {
  min-width: 12px;
  width: 12px;
  height: 12px;
}

.base-beta-checkbox.md {
  min-width: 14px;
  width: 14px;
  height: 14px;
}

.base-beta-checkbox.lg {
  min-width: 16px;
  width: 16px;
  height: 16px;
} */

.base-beta-checkbox.solid {
  padding: 2px;
}

.base-beta-checkbox:hover,
.base-beta-checkbox.active,
.base-beta-checkbox-container:hover .base-beta-checkbox {
  border-color: var(--qrw-border-success-primary);
}

.base-beta-checkbox.cross:hover,
.base-beta-checkbox.cross.active,
.base-beta-checkbox-container:hover .base-beta-checkbox.cross {
  border-color: var(--qrw-border-error-primary);
}

.base-beta-checkbox-cell {
  width: 100%;
  height: 100%;

  display: flex;
  background: var(--qrw-background-success-default);
  position: relative;

  border-radius: 1px;

  padding: 1px;
  box-sizing: border-box;
}

.base-beta-checkbox.cross .base-beta-checkbox-cell {
  background: var(--qrw-background-error-default);
}

.base-beta-checkbox.plus .base-beta-checkbox-cell {
  background: var(--qrw-background-success-default);
}

.cell-no-background {
  background-color: transparent !important;
}

.base-beta-checkbox-padder {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.checkbox-icon {
  width: 100%;
  height: 100%;
}

.base-beta-checkbox-padder.sm {
  padding: 2px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  max-height: 16px;
}

.base-beta-checkbox-padder.md {
  padding: 3px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  max-height: 20px;
}

.base-beta-checkbox-padder.lg {
  padding: 4px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-height: 24px;
}
</style>