<template>
  <div
    @click="$emit('click')"
    class="qrw-tag bold"
    :class="dynamicClass"
    :style="dynamicStyle"
  >
    <div class="tag-left" :class="{[size]: true}">
      <BaseIcon icon="tag-bold" :size="iconSize" v-if="withIcon" />
      <slot></slot>
    </div>

    <BaseIcon
      icon="cross"
      @click="$emit('remove')"
      class="qrw-text-content-base-tertiary"
      :size="iconSize"
      v-if="removable"
    />
  </div>
</template>

<script>
export default {
  props: {
    removable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "sm",
      validator: (v) => ["sm", "md", "lg"].includes(v),
    },
  },
  computed: {
    dynamicClass() {
      return {
        [`qrw-label-${this.size}`]: true,
        active: this.active,
        disabled: this.disabled,
      };
    },
    dynamicStyle() {
      const gaps = {
        sm: 6,
        md: 8,
        lg: 10,
      };

      const paddings = {
        lg: [8, 16, 8, 16],
        md: [6, 12, 6, 12],
        sm: [4, 8, 4, 8],
      };

      const borderRadius = {
        sm: 6,
        md: 8,
        lg: 10,
      };

      let [tp, rp, bp, lp] = paddings[this.size];

      if (this.removable) {
        rp = rp / 2;
      }

      tp -= 2;
      bp -= 2;

      if (this.withIcon) {
        lp -= 2;
      }

      return {
        "column-gap": `${gaps[this.size]}px`,
        padding: `${tp}px ${rp}px ${bp}px ${lp}px`,
        "border-radius": `${borderRadius[this.size]}px  `,
      };
    },
    iconSize() {
      return {
        sm: "xs",
        md: "sm",
        lg: "sm2",
      }[this.size];
    },
  },
};
</script>

<style scoped>
.qrw-tag {
  display: flex;
  border: 2px solid var(--qrw-border-base-secondary);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background: var(--qrw-background-base-primary);
  color: var(--qrw-content-base-secondary);

  width: fit-content;
}

.qrw-tag.active {
  background: var(--qrw-background-base-inverse-secondary);
  color: var(--qrw-content-base-inverse-primary);
  border-color: var(--qrw-background-base-inverse-secondary);
}

.qrw-tag.disabled {
  background: var(--qrw-background-base-tertiary);
  color: var(--qrw-content-base-tertiary);
  border-color: var(--qrw-border-base-tertiary);
  cursor: not-allowed;
}

.tag-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag-left.sm {
  column-gap: 4px;
}

.tag-left.md {
 column-gap: 6px;
}

.tag-left.lg {
  column-gap: 8px;
}
</style>