<template>
  <div
    class="qrw-workspace-avatar"
    :class="{
        'with-image': !!imageUrl
    }"
    :style="containerStyles"
  >
    <BaseIcon
      icon="shop"
      :size="iconSize"
      v-if="!imageUrl"
    />
    <img
      class="avatar-image"
      :src="imageUrl"
      v-else
    >
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    size: {
      type: Number,
      default: 80
    }
  },
  computed: {
    iconSize() {
      return (
        {
          80: 'md2',
          72: 'md2',
          64: 'md2',
          56: 'md',
          48: 'sm4',
          40: 'sm2',
          32: 'sm'
        }[this.size] || 'sm'
      )
    },
    containerStyles() {
      const borderRadiusMappings = {
        80: 16,
        72: 16,
        64: 16,
        56: 14,
        48: 12,
        40: 10,
        32: 8
      }

      //compensate border width
      const containerSize = this.imageUrl ? this.size - 2 : this.size

      return {
        width: `${containerSize}px`,
        height: `${containerSize}px`,
        'max-width': `${containerSize}px`,
        'border-radius': `${borderRadiusMappings[this.size] || 16}px`
      }
    }
  }
}
</script>

<style scoped>
.qrw-workspace-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--qrw-background-base-tertiary-10);

  position: relative;

  color: var(--qrw-content-base-tertiary);
  overflow: hidden;

  cursor: pointer;
}

.qrw-workspace-avatar.with-image {
  border: 1px solid var(--qrw-border-base-tertiary);
}

.avatar-image {
  width: 100%;
  height: 100%;

  object-fit: cover;
}
</style>