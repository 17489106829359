<template>
  <div class="qrw-common-input-container">
    <input
      :type="type"
      :value="value"
      :disabled="disabled"
      @focus="isFocused = true; $emit('focus')"
      @blur="isFocused = false;  $emit('blur')"
      @keyup="onKeyUp"
      @keydown="(ev) => $emit('keydown', ev)"
      @input="onInput"
      :readonly="readonly"
      :autocomplete="autocomplete"
      class="qrw-common-input-element"
      :class="{
        [`qrw-paragraph-${size}`]: true,
        'state-error': invalid,
        [`size-${size}`]: true,
        'state-disabled': disabled,
        'state-focus': isFocused
      }"
      :inputmode="inputmode"
      :placeholder="placeholder"
      ref="rawInput"
    />
  </div>
</template>

<script>
import './inputCommons.scss'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 'sm',
      validator: v => ['sm', 'md', 'lg'].includes(v)
    },
    invalid: {
      type: Boolean,
      default: false
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: String
    }
  },
  data() {
    return {
      isFocused: false
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },
    onKeyUp(ev) {
      this.$emit('keyup', ev)

      if (ev.keyCode === 13) this.$emit('enterPress')
    },
    getInputElement() {
      return this.$refs.rawInput
    }
  }
}
</script>

<style>
</style>