<template>
  <div
    class="select-container"
    :class="{
      disabled: disabled,
      [`size-${size}`]: true,
      'select-invalid': invalid,
      'focused': isFocused
    }"
  >
    <select
      class="qrw-input-text"
      :class="dynamicClass"
      :disabled="disabled"
      :value="value"
      @input="onInput"
      @focus="isFocused = true;"
      @blur="isFocused = false; $emit('blur')"
      :id="selectId"
    >
      <slot></slot>
    </select>
    <BaseIcon
      icon="chevron-down"
      size="xs"
      :class="{ active: !disabled }"
      class="select-arrow"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number, Object, Array, Boolean],
    size: {
      type: String,
      default: 'sm'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    selectId: {
      type: String,
      default: null
    }
  },
  watch: {
    value() {
      console.log(this.value)
    }
  },
  data() {
    return {
      isFocused: false
    }
  },
  methods: {
    onInput(ev) {
      this.$emit('input', ev.target.value)
    }
  },
  computed: {
    isSelected() {
      return this.value !== null && this.value !== undefined && this.value !== ''
    },
    dynamicClass() {
      return {
        select: true,
        'select-active': this.isSelected,
        'select disabled': this.disabled,
        'select-placeholder': !this.isSelected,
        'qrw-input-text-lg': this.size == 'lg'
      }
    }
  }
}
</script>

<style scoped>
.select {
  position: relative;
  background: transparent;
  width: 100%;
  padding: 0;
  border-radius: 8px;
  border: none;
  color: var(--qrw-background-base-primary);
  -webkit-appearance: none;
  outline: none;
  z-index: 1;
}

.select-placeholder {
  color: var(--qrw-content-base-tertiary);
  -webkit-text-fill-color: var(--qrw-content-base-tertiary);
}

.select.disabled {
  cursor: not-allowed;
  border-radius: inherit;
  border: none;
  opacity: 1;
  color: var(--black-color);
}

.select.disabled + .select-arrow {
  color: var(--qrw-content-base-tertiary);
}

.select-arrow {
  position: relative;
  display: flex;
  right: 0;
  margin-left: 8px;
  z-index: 1;
  color: var(--qrw-content-base-tertiary);
}

.select-arrow.active {
  color: var(--v1-grey-10-color);
}

.select-active {
  color: var(--qrw-content-base-primary);
  border-color: var(--qrw-content-base-primary);
}

.select-container {
  position: relative;
  display: flex;
  background: var(--qrw-background-base-primary);
  padding: 7px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid var(--v1-grey-6-color);
}

.select-container.disabled {
  background: var(--qrw-background-base-tertiary);
  border: none;
  padding: 8px;
}

.select-container.focused {
  border-color: var(--v1-grey-10-color);
}

.select-container.size-lg {
  padding: 10px;
}

.select-invalid {
  padding: 7px;
  border: 1px solid var(--red-2-color);
}
</style>

