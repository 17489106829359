<template>
  <div class="radio-toggle" @click="emitValue">
    <div
      class="radio-item"
      :class="{ disabled: readonly || disabled }"
      :style="
        !!value
          ? `background-color:${bgColor};border:1px solid transparent`
          : ''
      "
      v-if="!checkmark"
    ></div>
    <BaseIcon :icon="value ? 'checker-toggle-on' : 'checker-toggle-off'" v-else/>
    <h3 class="qrw-heading-sm circle-label">
      <slot></slot>
    </h3>
  </div>
</template>

<script>
export default {
  props: {
    value:Boolean,
    checkmark: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bgColor:{
      type:String,
      default:'var(--green-7-color)'
    }
  },
  methods: {
     emitValue() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style scoped>
.radio-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-item {
  width: 14px;
  height: 14px;
  border: 1px solid var(--v1-grey-7-color);
  border-radius: 50px;
}

.radio-item.disabled {
  cursor: not-allowed;
  opacity: 0.52;
}

.circle-label {
  margin-left: 8px;
  color: var(--v1-grey-7-color);
}
</style>