<template>
  <div
    class="base-counter"
    :class="[size]"
  >
    <BaseIconButton
      variant="ghost"
      @click="$emit('input', value - 1)"
      :size="buttonsSize"
      textColor="content-base-primary"
      icon="minus"
      :disabled="(min !== null && value <= min) || disabled"
    />
    <div :class="`qrw-label-${size} bold qrw-text-content-base-primary counter-value`">
      {{ value }}
    </div>
    <BaseIconButton
      variant="ghost"
      @click="$emit('input', value + 1)"
      :size="buttonsSize"
      textColor="content-base-primary"
      icon="plus"
      :disabled="(max !== null && value >= max) || disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    size: {
      type: String,
      default: 'sm',
      validator: v => ['sm', 'md', 'lg'].includes(v)
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonsSize() {
      return {
        sm: 'xs',
        md: 'sm',
        lg: 'md'
      }[this.size]
    }
  }
}
</script>

<style scoped>
.base-counter {
  display: flex;
  align-items: center;
  column-gap: 4px;

  border-radius: 8px;
  border: 1px solid var(--qrw-border-base-tertiary);
  width: fit-content;
}

.base-counter.sm {
  padding: 2px 3px;
  column-gap: 3px;
}

.base-counter.md {
  padding: 3px 4px;
  column-gap: 4px;
}

.base-counter.lg {
  padding: 3px 4px;
  column-gap: 4px;
}

.counter-value {
  width: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>