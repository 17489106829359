<template>
  <div
    class="unit-input-wrapper"
    :class="{'disabled':disabled}"
    :style="{
      width: fullWidth ? '100%' : 'fit-content',
    }"
  >
    <!-- TODO: is it possible to extend baseinput props easily, fix it :D -->
    <BaseInput
      :valid="valid"
      :value="value"
      :position="position"
      @input="(v) => $emit('input', v)"
      :required="required"
      :inputmode="inputmode"
      :pattern="pattern"
      :placeholder="placeholder"
      :iconPosition="iconPosition"
      :fullWidth="fullWidth"
      :leftIcon="leftIcon"
      :righIcon="rightIcon"
      :type="type"
      :inputId="inputId"
      :icon="icon"
      :mask="mask"
      :size="size"
      @blur="$emit('blur')"
      :overrideColor="overrideColor"
      :disabled="disabled"
      :class="{'disabled':disabled}"
    />
    <BaseInput
      class="unit-box"
      :overrideColor="overrideColor"
      :class="{'disabled':disabled, [unitClass]: !!unitClass}"
      :size="size"
      hasUnit
      disabled
      :value="unitPlaceholder"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    position: String,
    placeholder: {
      type: [String, Number, Object, Boolean],
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    leftIcon: {
      type: String,
    },
    rightIcon: {
      type: String,
    },
    iconPosition: {
      type: String,
      default: "left",
    },
    size: {
      type: String,
      default: "sm",
    },
    hasUnit: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    value: [String, Number, Object],
    valid: {
      type: Boolean,
      default: true,
    },
    inputId: {
      type: String,
      default: null,
    },
    mask: {
      type: String,
      default: null,
    },
    pattern: String,
    inputmode: {
      type: String,
      default: 'text'
    },
    unitPlaceholder: {
      type: String,
      default: "units",
    },
    overrideColor: String,
    unitClass: String
  },
};
</script>


<style scoped>
.unit-input-wrapper {
  background: var(--v1-rey-3-color);
  width: fit-content;
  border-radius: 4px;
  display: flex;
}

.unit-input-wrapper.disabled {
  background: var(--v1-grey-3-color);
}

.unit-box {
  width: fit-content !important;
  align-items: center !important;
}
</style>